<style lang="scss" src="./styles.scss"/>
<script>
import Header from '@/components/Header.vue'

export default ({
    name: 'Inv',
    components: {
        Header
    }
})
</script>

<template>
    <Header />
    <main>
        <div >
        <div >
            

            <h1 >
               Investor reletions
            </h1>
          <br> <br>
            <div class="col-12 col-lg-3 text-right mt-4 mt-lg-0"></div>
        </div>

        <section class="text-left text-secondary mb-2 pb-2 px-lg-5 mx-lg-5">
            <div class="my-4 py-4">
                <a
                    href="https://drive.google.com/file/d/1RIdHtyZQrFXKitYSch4nCTdFtnoOcoCv/view?usp=sharing"
                    target="_blank">
                   
                </a>
            </div>

            
                <p>Longevity InTime BioTech creates a conglomerate of companies in the Longevity area and offering swap of equity to the leading Biotech, AI, computer vision, medtech, big data, life sciences startups.</p>
                <p>Have a longevity product or service,</p>
            

          
                <p>
                    Fund BroadVC&nbsp;is contributing to&nbsp;improving the investment climate in&nbsp;the
                    fight against aging and has pledged assistance to&nbsp;provide an&nbsp;investment visa
                    (EB5) in&nbsp;the United States for Longevity InTime investors.
                </p>
                <p>The companies also signed a&nbsp;cooperation contract.</p>
           

        </section>

        <section class="img-1">
            <a
                href="https://www.f6s.com/intimedigitizemetolive120?follow=1"
                target="_blank"
                title="Follow Longevity InTime BioTech on F6S"
                data-toggle="tooltip"
                data-placement="top"

                
                data-html="true"
                data-title="Follow Longevity InTime BioTech on F6S."
                class="investor-link mx-2">
            </a>

            <a
                href="https://www.crunchbase.com/organization/longevity-intime-digital"
                target="_blank"
                title="Longevity InTime BioTech on Crunchbase"
                data-toggle="tooltip"
                data-placement="top"
                data-html="true"
                data-title="Longevity InTime BioTech on Crunchbase."
                class="investor-link mx-2">
                   
            </a>

            <a
                href="https://gust.com/companies/intime-digitize-me-to-live-120-1-stage"
                target="_blank"
                title="Longevity InTime BioTech on Gust"
                data-toggle="tooltip"
                data-placement="top"
                data-html="true" 
                data-title="Longevity InTime BioTech on Gust."
                class="investor-link mx-2">

            </a>

            <a
                href="https://www.ai-businessdirectory.net/wilmington/ai-companies-providing-services-for-healthcare/longevity-intime-biotech?from=badge"
                title="Find me on Artificial Intelligence Business Directory"
                target="_blank"
                rel="nofollow noopener"
                data-toggle="tooltip"
                data-placement="top"
                data-html="true"
                data-title="Find me on Artificial Intelligence Business Directory"
                class="investor-link mx-2">

                <img
                    src="https://www.ai-businessdirectory.net/images/memberbadge.png"
                    alt="Find me on Artificial Intelligence Business Directory"
                    style="height: auto; border:0 ;padding-left:40px"
                    width="100"
                    height="63"
                    />
            </a>
        </section>
        </div>
    </main>
</template>
