<template>
<i class="icon" :class='`icon--${symbol}`'>
    <img v-if="symbol === 'logo'" src="./logo.svg"/>
    <img v-else-if="symbol === 'asteriskColor'" src="./asterisk-color.svg"/>
    <svg v-else>
        <use :xlink:href='`${path}#${symbol}`' :href='`${path}#${symbol}`'/>
    </svg>
</i>
</template>

<script>
/**
 * SvgIcon component for displaying an icon from a sprite.
 * 
 *@updated: Mon, 27 Jun 2022 14:18:43 GMT
 * @name: <SvgIcon>
 * 
 * 
 * @component
 * @example
 * // Usage in a Vue template:
 * <SvgIcon symbol="info" />
 *
 * @props {String} symbol - The ID of the SVG symbol to display.
 *
 * @param {String} symbol - The ID of the SVG symbol to display.
 * @data {String} path - The path to the SVG sprite file.
 */


let spritePath = require('./icons.svg');
spritePath = spritePath.default || spritePath;
export default {
    name: 'SvgIcon',
    props: {
        symbol: {type: String, default: 'info'}, // svg symbol's id
    },
    data() {
        return {
            path: spritePath,
        };
    },
};
</script>

<style lang="scss">
.icon {
    display: inline-block;
    font-style: normal;
    width: 2rem;
    height: 2rem;
    color: inherit;
    & > img,
    & > svg {
        fill: currentColor;
        display: block;
        height: 100%;
        width: 100%;
    }
}
</style>