<template>
  <div class="auth">
    <code-verificator
      v-if="codeState"
      @resendCode="resendCode()"
      @codeSubmit="submitAuth($event)"
      @changeEmail="changeEmail()"
    />
    <template v-else>
      <span class="auth__header">Sign up</span>
      <br />
      <span
        >By creating an account you agree with our
        <router-link :to="{ path: '/policies/terms-of-service' }"
          >Tems of Service</router-link
        >
        and
        <router-link :to="{ path: '/policies/privacy-policy' }"
          >Privacy Policy</router-link
        ></span
      >
      <div class="auth__content">
        <InputText placeholder="Email Or Phone" v-model="username" />
        <InputText placeholder="Password" v-model="password" type="password" />
      </div>
      <div class="auth__footer">
        <div class="auth__footer--providers">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <g clip-path="url(#clip0_37537_2590)">
              <path
                d="M35.6178 18.405C35.6178 17.22 35.5128 16.095 35.3328 15H18.3828V21.765H28.0878C27.6528 23.985 26.3778 25.86 24.4878 27.135V31.635H30.2778C33.6678 28.5 35.6178 23.88 35.6178 18.405Z"
                fill="currentColor"
              />
              <path
                d="M18.3824 35.9986C23.2424 35.9986 27.3074 34.3786 30.2774 31.6336L24.4874 27.1336C22.8674 28.2136 20.8124 28.8736 18.3824 28.8736C13.6874 28.8736 9.71239 25.7086 8.28738 21.4336H2.31738V26.0686C5.27238 31.9486 11.3474 35.9986 18.3824 35.9986Z"
                fill="currentColor"
              />
              <path
                d="M8.28781 21.4347C7.91281 20.3547 7.71781 19.1997 7.71781 17.9997C7.71781 16.7997 7.92781 15.6447 8.28781 14.5647V9.92969H2.31781C1.08781 12.3597 0.382812 15.0897 0.382812 17.9997C0.382812 20.9097 1.08781 23.6397 2.31781 26.0697L8.28781 21.4347Z"
                fill="currentColor"
              />
              <path
                d="M18.3824 7.125C21.0374 7.125 23.4074 8.04 25.2824 9.825L30.4124 4.695C27.3074 1.785 23.2424 0 18.3824 0C11.3474 0 5.27238 4.05 2.31738 9.93L8.28738 14.565C9.71238 10.29 13.6874 7.125 18.3824 7.125Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_37537_2590">
                <rect width="36" height="36" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <g clip-path="url(#clip0_37537_2596)">
              <path
                d="M18 36.0038C27.9411 36.0038 36 27.945 36 18.0039C36 8.06277 27.9411 0.00390625 18 0.00390625C8.05887 0.00390625 0 8.06277 0 18.0039C0 27.945 8.05887 36.0038 18 36.0038Z"
                fill="currentColor"
              />
              <path
                d="M25.0066 23.2062L25.8039 18.0019H20.8122V14.6251C20.8122 13.2025 21.5084 11.8129 23.746 11.8129H26.0166V7.3831C26.0166 7.3831 23.9561 7.03125 21.9868 7.03125C17.8759 7.03125 15.1877 9.52204 15.1877 14.0353V18.0019H10.6162V23.2062H15.1877V35.7841C16.104 35.9284 17.0432 36.0018 18 36.0018C18.9568 36.0018 19.8959 35.9259 20.8122 35.7841V23.2062H25.0066Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_37537_2596">
                <rect width="36" height="36" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="36"
            viewBox="0 0 30 36"
            fill="none"
          >
            <g clip-path="url(#clip0_37537_2599)">
              <path
                d="M28.3716 12.2724C28.1628 12.4344 24.4764 14.5116 24.4764 19.1304C24.4764 24.4728 29.1672 26.3628 29.3076 26.4096C29.286 26.5248 28.5624 28.998 26.8344 31.518C25.2936 33.7356 23.6844 35.9496 21.2364 35.9496C18.7884 35.9496 18.1584 34.5276 15.3324 34.5276C12.5784 34.5276 11.5992 35.9964 9.36 35.9964C7.1208 35.9964 5.5584 33.9444 3.762 31.4244C1.6812 28.4652 0 23.868 0 19.5048C0 12.5064 4.5504 8.7948 9.0288 8.7948C11.4084 8.7948 13.392 10.3572 14.886 10.3572C16.308 10.3572 18.5256 8.7012 21.2328 8.7012C22.2588 8.7012 25.9452 8.7948 28.3716 12.2724ZM19.9476 5.7384C21.0672 4.41 21.8592 2.5668 21.8592 0.7236C21.8592 0.468 21.8376 0.2088 21.7908 0C19.9692 0.0684 17.802 1.2132 16.4952 2.7288C15.4692 3.8952 14.5116 5.7384 14.5116 7.6068C14.5116 7.8876 14.5584 8.1684 14.58 8.2584C14.6952 8.28 14.8824 8.3052 15.0696 8.3052C16.704 8.3052 18.7596 7.2108 19.9476 5.7384Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_37537_2599">
                <rect width="29.304" height="36" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <button class="button auth__footer--submit" @click="signup()">Sign up</button>
      </div>
      <div class="auth__text">
        Already have an account?
        <span class="auth__text__button" @click="goToLogin()">log in</span>
      </div>
    </template>
  </div>
</template>
<script>
import codeVerificator from "@/components/Auth/AuthForms/codeVerificator.vue";
import InputText from "@/components/input-text/InputText.vue";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    codeVerificator,
    InputText,
  },
  data() {
    return {
      username: "",
      password: "",
      attributes: {},
      generetedUsername: "",
      emailRegex: /^[\w\.-]+@[\w\.-]+\.\w+$/,
      phoneRegex: /^\+\d{10,12}$/,
      codeState: false,
    };
  },
  mounted() {
    this.codeState =
      !!JSON.parse(localStorage.getItem("authComp"))?.isCodeVerifying &&
      JSON.parse(localStorage.getItem("authComp"))?.username;
  },
  methods: {
    async changeEmail() {
      localStorage.setItem("authComp", JSON.stringify({
        username: this.generetedUsername,
        type: "signup",
        isCodeVerifying: false,
      }))
      this.codeState = false;
    },

    goToLogin() {
      localStorage.setItem("authComp", JSON.stringify({
        type: "login"
      }))
      this.$store.commit("switchAuthFormType", "login");
    },

    resendCode() {
      try {
        Auth.resendSignUp(JSON.parse(localStorage.getItem("authComp"))?.username);
      } catch (e) {}
    },

    async signup() {
      try {
        this.generetedUsername = uuidv4();
        if (this.emailRegex.test(this.username)) {
          this.attributes.email = this.username;
          this.attributes.updated_at = new Date().valueOf().toString();
          // attributes['custom:groups'] = ['all', 'patient']
          const data = await Auth.signUp({
            username: this.generetedUsername,
            password: this.password,
            attributes: this.attributes,
            autoSignIn: {
              enabled: false,
            },
          });
          localStorage.setItem("authComp", JSON.stringify({
            username: this.generetedUsername,
            password: this.password,
            type: "signup",
            isCodeVerifying: true,
          }))
          this.codeState = true;
        } else if (this.phoneRegex.test(this.username)) {
          this.attributes.phone_number = this.username;
          this.attributes.updated_at = new Date().valueOf().toString();
          // attributes['custom:groups'] = ['all', 'patient']
          const data = await Auth.signUp({
            username: this.generetedUsername,
            password: this.password,
            attributes: this.attributes,
            autoSignIn: {
              enabled: false,
            },
          });
          localStorage.setItem("authComp", JSON.stringify({
            username: this.generetedUsername,
            password: this.password,
            type: "signup",
            isCodeVerifying: true,
          }))
          this.codeState = true;
        } else {
          console.log("email or phone is invalid");
        }
      } catch (e) {
        console.log(e);
      }
    },

    async submitAuth(code) {
      try {
        const data = await Auth.confirmSignUp(
          this.generetedUsername || JSON.parse(localStorage.getItem("authComp"))?.username,
          code
        );
        await Auth.signIn({
          username: JSON.parse(localStorage.getItem("authComp"))?.username,
          password: JSON.parse(localStorage.getItem("authComp"))?.password,
        });
        const user = await Auth.currentAuthenticatedUser({
          // Optional, By default is false. If set to true,
          // this call will send a request to Cognito to get the latest user data
          bypassCache: false,
        });
        this.$store.commit("user/profile", user.attributes);
        localStorage.setItem("authComp", JSON.stringify({ type: "login", isCodeVerifying: undefined }))
        this.$store.commit("switchAuthFormType", "login");
        this.codeState = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.auth {
  max-width: 532px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  &__header {
    color: var(--masstrich-blue);
    font-family: DM Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__content {
    padding-top: 72px;
    display: flex;
    gap: 48px;
    flex-direction: column;

    &--input {
      width: 100%;
      display: flex;
      padding: 8px 18px;
      align-items: center;
      border: none;
      border-radius: 6px;
      background-color: var(--water);
      gap: 10px;
    }

    input {
      &::placeholder {
        color: darken($water, 10%);
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: darken($water, 10%);
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  &__recovery-password {
    display: flex;
    height: 18px;
    justify-content: flex-end;
    padding-top: 8px;
    align-items: center;
    flex-shrink: 0;

    &--button {
      color: var(--grey4);
      text-align: right;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__footer {
    padding-top: 89px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--providers {
      padding-top: 8px;
      display: flex;
      height: 42px;
      padding-right: 0px;
      color: var(--masstrich-blue);
      justify-content: center;
      align-items: flex-start;
      gap: 48px;
      flex-shrink: 0;
    }

    &--submit {
      display: flex;
      width: 195px;
      height: 47px;
      padding: 8px 81px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      white-space: nowrap;
      flex-shrink: 0;
      color: var(--white);
      border-radius: 140px;
      border-width: 0px;
      background: var(--accent);
      box-shadow: 6px 6px 36px 0px rgb(0 0 0 / 65%);
    }
  }

  &__text {
    padding-top: 128px;
    text-align: center;
    color: var(--indigo);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &__button {
      color: var(--accent);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}
</style>

