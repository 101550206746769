import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({ storage: window.localStorage })

/**
 *
 */
/** @type {import('vuex').Module<RootState>} */
const store = {
	plugins: [vuexLocal.plugin],

	state: {
		loading: true,
		isAuthOpen: false,
		isMobile: false,
		isMenuOpen: false,
		languageBox: false,
		user: 0,
		cart: {},
		mainFrame: true, authType: 'login'
	},
	modules: {
		// user,

	},
	actions: {
		/** Update user dashboard data
		 * @param {ActionContext} context
		 * @return {Promise<any>}
		 */
		async fetchInfo({ rootGetters }) {

			return {};
		},
		cartge() {

				return this.state.cart;
			},
		},
		getters: {
			$client: (state, getters) => getters['$client/axios'],
			username: (state) => {
				if (!state.user.profile) return undefined;
				return state.user.profile?.email || state.user.profile?.phone_number || state.user.profile?.sub;
			},
		},
		mutations: {
			switchIsMobile(state, payload) {
				state.isMobile = payload
			},
			switchMainFrame(state, payload) {
				state.mainFrame = payload
			},
			switchIsAuthOpen(state, payload) {
				state.isAuthOpen = payload
			},
			user(state, payload) {
				state.user = payload
				console.log('user state changed:', state.user)
			},
			cart(state, payload) {
				state.user = payload
			},
			cartAdd(state, p) {
				state.cart[p.handle] = p
				console.log("add ", p)
				return state.cart;
			},
			cartR(state, p) {
				// check if product.qtt is > 1, in that case reduce qtt by 1. If it's equal to 1, remove the product from the cart
				if (state.cart[p].qtt > 1) {
					state.cart[p].qtt -= 1
				} else {
					delete state.cart[p]
				}
				console.log("R ", p)
				return state.cart;
			},
			// add qtt to product in cart
			cartAddQtt(state, p) {
				state.cart[p].qtt += 1
				console.log("A ", p)
				return state.cart;
			},
			cartC(state, p) {
				state.cart={}
				console.log("R ", p)
				console.log('cart state changed:', state.cart)
				return state.cart;
			},
			switchAuthFormType(state, payload) {
				state.authType = payload
			}

	},
}

export default createStore(store);
