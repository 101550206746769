<template>
	<div class="Authorizer" :key="authAction">
		<div class="Authorizer__form">
			<div>
				<SignUp v-if="$store.state.authType === 'signup'" />
				<Login v-else />
			</div>
		</div>
	</div>
</template>

<script>
import SignUp from "./AuthForms/SignupForm.vue";
import Login from "./AuthForms/SigninForm.vue";

export default {
	name: "Authorizer",
	
  components: {
		Login,
		SignUp
	},

	mounted() {
		this.$store.commit("switchAuthFormType", JSON.parse(localStorage.getItem("authComp"))?.type || "login");
	},
};
</script>
<style lang="scss">
@keyframes navAuthorizer {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
}

.Authorizer {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: stretch;
	box-sizing: border-box;
	margin: 0 auto;
	height: 100%;
	width: 100%;
	// max-width: 500px;
	// max-height: 700px;
	font-size: 1.5rem;
	padding: 75px 0;
	animation: navAuthorizer 0.375s ease-in-out 0.125s 1 normal both;

	a {
		cursor: pointer;
	}

	&__form {
		margin: 2rem 0 0;
		flex: 1 0 auto;
		.input-text {
			width: 100%;
			display: flex;
			font-size: 1.125rem;
		}
		.input-text + .input-text {
			margin-top: 2rem;
		}
	}
}
</style>
