<template>
	<div class="mini-app">
		<h1>Get ready to revolutionize your wellness journey with Longevity Mini app on telegram!</h1>
		<p class="mini-app__text">
			Backed by cutting-edge technologies and founded by a renowned Hollywood producer, this innovative app is set to
			change the game.
		</p>
		<p class="mini-app__text">
			Transform your life, one small step at a time, with our expertly designed programs and tools.
		</p>
		<p class="mini-app__text">Join the movement and unlock a longer, healthier, and happier you … InTime!</p>
		<p class="mini-app__text">May The Longevity Be With You!</p>
		<p class="mini-app__text">Coming soon...</p>
		<h2>Best character of the Game / Coin with a prize fund of 10,000$ worth of LONG coins</h2>
		<p class="mini-app__text">
			We launch a contest for the main image of the Longevity Coin (LONG) Mini App Game in Telegram and a potential
			character of the Coin / Game.
		</p>
		<p class="mini-app__text">Send your works <a href="https://t.me/LongevityCoinGroup" target="_blank">to our</a></p>
		<p class="mini-app__text">The first 3 winners will get 10,000$ worth in Longevity Coin (LONG) coins each!</p>
		<p class="mini-app__text">
			To be eligible to participate, each contestant should bring at least 10 friends in to each Group & Channel below
			and <a href="https://t.me/LongevityCoin_Official" target="_blank">send us</a> any proof that these friends are
			coming from your referral.
		</p>
		<p class="mini-app__text">
			Your 10 friends should join each of the following channels:<br />
			1.<a href="https://t.me/LongevityCoinGroup" target="_blank">Longevity Coin Group</a><br />
			2.<a href="https://t.me/LongevityInTime" target="_blank">Longevity InTime Channel</a> <br />
			3.<a href="https://www.instagram.com/teterinolegjr?igsh=NTc4MTIwNjQ2YQ==" target="_blank"
				>Our CEO’s Instagram personal page</a
			><br />
			4.<a href="https://youtube.com/@LongevityInTimeBioTech?si=coVVBhJOU226NrVO" target="_blank"
				>Corporate YouTube channel</a
			><br />
			5.<a href="https://www.tiktok.com/@longevityintime?_t=8kpx3svAVeM&_r=1" target="_blank"
				>Corporate TikTok channel</a
			><br />
			6.<a
				href="https://www.instagram.com/longevityintimebiotech?igsh=MTZtY3ZqNHIweWRzYQ%3D%3D&utm_source=qr"
				target="_blank"
				>Corporate Instagram channel</a
			><br />
			7.<a href="https://www.linkedin.com/company/longevityintime/" target="_blank">Corporate LinkedIn page</a><br />
			8.<a href="https://whatsapp.com/channel/0029VaQjVKK5q08UivEH681c" target="_blank">Corporate WhatsApp channel</a><br />
			9.<a href="https://www.facebook.com/share/KuYdt7Un4GPzjtQV/?mibextid=LQQJ4d" target="_blank">Our CEO’s Facebook personal Page</a><br />
			10.<a href="https://x.com/longevityintime?s=21" target="_blank">Corporate Twitter page</a><br />
			11.<a href="https://twitter.com/teterinoleg?lang=en" target="_blank">Our CEO’s Twitter personal Page</a><br />
		</p>
		<p class="mini-app__text">Longevity Like-minders, we are looking for you!</p>
		<p class="mini-app__text">May The Longevity Be With You!</p>
		<div class="mini-app__images">
			<img class="mini-app__image" src="../assets/img/photo_game_1.jpg" />
			<img class="mini-app__image" src="../assets/img/photo_game_2.jpg" />
			<img class="mini-app__image" src="../assets/img/photo_game_3.jpg" />
		</div>
	</div>
</template>

<script>
export default {
	name: "MiniApp",
};
</script>

<style scoped>
.mini-app {
	text-align: center;
	padding: 20px;
	width: 85%;
	margin: 0 auto;
}

.mini-app h1 {
	color: #3683fc;
}

.mini-app p {
	font-size: 18px;
	line-height: 30px;
	margin-top: 10px;
	margin-bottom: 0;
}

.mini-app h2 {
	font-size: 20px;
	line-height: 30px;
	margin: 80px auto 0;
}

.mini-app a {
	cursor: pointer;
	text-decoration: none;
	color: #3683fc;
}

.mini-app__text {
	text-align: left;
}

.mini-app__images {
	display: flex;
	gap: 10px;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 20px;
}

.mini-app__image {
	width: 450px;
	border-radius: 20px;
}

@media screen and (max-width: 450px){
	.mini-app__image {
		width: 300px;
	}	
}
</style>
