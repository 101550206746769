<template>
<div class="Auth">
  <div class="Auth__login">
    <User v-if="$store.getters.username"/>
    <Authorizer v-else />
  </div>
</div>
</template>

<script>
import Authorizer from './Authorizer.vue';
import User from './user.vue';

export default {
  name: 'Auth',
  components: {
    User,
    Authorizer
  },
}
</script>

<style lang="scss">
.Auth {
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: 1fr;
  background-color: var(--light);
  backdrop-filter: saturate(150%) blur(12px);
  &__login {
    border-left: 1px solid white;
    display: flex;
    flex-flow: column nowrap;
    // justify-content: center;
  }
  &__promos {
    margin-top: 6rem;
    background: linear-gradient(0deg, rgba(54, 131, 252, 0.05), rgba(54, 131, 252, 0.05)), #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: lightgrey;
    h1{
      color: #0C1E3C;
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 0;
    }
    p{
      font-weight: 500;
      font-size:20px;
      line-height: 36px;
      margin-top: 0;
      color: #21355C;
    }
    &__getBTns{
      display: flex;
      .google{
        margin-right: 1rem;
      }
    }
    .report-analysis-img{
      margin-top: 2rem;
    }
  }
  &__logo {
    position: absolute;
    left: 0;
    top: 0;
    margin: 2rem 0 0 4rem;
    .icon {
      display: block;
      height: 64px;
      width: 131px;
    }
  }
}
</style>