<style lang="scss" src="./InputText.scss"></style>

<template>
<div class="InputText"
    :class="{
        [`InputText--${status}`]: !!status,
        [`InputText--${size}`]: !!size,
        [$attrs.class]: !!$attrs.class,
    }"
    :aria-current="inputFocus ? '' : null"
    :aria-disabled="isDisabled ? '' : null"
    :data-type="type || 'text'"
    :style="$attrs.style">

    <input class="InputText__input" ref="input"
        :type="showPassword ? 'text' : type"
        :id="inputId"
        :name="inputName"
        :disabled="isDisabled ? '' : null"
        :readonly="isReadonly ? '' : null"
        @input='inputHandler'
        @focus='inputFocus = true'
        @blur='inputFocus = false'
        :value='inputValue'
        v-bind='{...$attrs, class: {}, style: {}}'/>

    <span class="InputText__note" v-if="note">{{note}}</span>

    <span class="InputText__icon" v-if="iconId || iconClickable">
        <span v-if="!iconClickable"><icon-svg :symbol='iconId'/></span>
        <button v-else
			class="button"
            type="button"
            tabindex="-1"
            aria-label="click"
            @click.prevent.stop='clickIcon'>
            <icon-svg :symbol='iconId'/>
        </button>
    </span>

    <sup class="InputText__label"
        v-if='label && inputFocus'>{{label}}</sup>

    <span class="InputText__error" v-if="error && !errorHidden"
      @click="errorHidden = true"
      v-text="error"/>
</div>
</template>

<script>
/**
 * @updated: Sun, 10 Jul 2022 14:18:43 GMT
 * @name: InputText
 * Figma mockup: https://www.figma.com/file/20GUJWT3OckRt1NP76bMve/Intime-Digital?node-id=12759%3A9479
 * image file: ![mage preview](https://gitlab.com/gl.frontend/app_mvp/-/raw/dprincecoder-main-patch-92028/src/docsImages/Annotation_2023-09-29_125232.png)
 */
export default {
    inheritAttrs: false,
    emits: ['input', 'update:modelValue'],
    props: {
        value: {type: [String, Number], default: null},
        modelValue: {type: [String, Number], default: null},
        error: {type: String, default: ''},
        label: {type: String, default: ''},
        type: {type: String, default: null},
        icon: {type: String, default: null},
        note: {type: String, default: null},
        size: {
            type: String, // 'large'|'small' (or null - normal)
            default: null,
            validator(value) {
                return !value ? true : ['large', 'small'].includes(value);
            }
        },
        status: {
            type: String, // 'success'|'warning'|'error' (or null - normal)
            default: null,
            validator(value) {
                return !value ? true : ['success', 'warning', 'error'].includes(value);
            }
        },
        disabled: {type: [String, Boolean], default: null},
        readonly: {type: [String, Boolean], default: null},
    },
    data() {
        const inputName = this.$attrs.name || `name-${Math.random().toString(36).substring(2)}`;
        return {
            inputName,
            inputId: this.$attrs.id || inputName,
            inputFocus: false, // input has focus
            inputValue: '', // innner "value" container
            showPassword: false,
            passwordTimeout: -1, // showPassword`s setTimeout id
            errorHidden: false,
            iconId: null,
        };
    },
    beforeMount() {
      // set eye icon for type=password
      if (this.$props.icon) this.iconId = this.$props.icon;
      else if (this.type === 'password') this.iconId = 'eye';
      else if (this.type === 'date') this.iconId = 'calendar';

      // set initial inputValue
      if (this.$props.modelValue === null) this.inputValue = this.$props.value;
      else this.inputValue = this.$props.modelValue;
    },
    methods: {
        togglePassword() {
            if (this.showPassword) return this.showPassword = false;

            this.showPassword = true;
            if (this.passwordTimeout) clearTimeout(this.passwordTimeout);
            this.passwordTimeout = setTimeout(() => this.showPassword = false, 3000);
        },
        inputHandler() {
          let value = this.input?.value || this.inputValue;

          this.$emit('update:modelValue', value);
          this.$emit('input', value);
          this.inputValue = `${value}`;
          this.errorHidden = true;
        },
        clickIcon(event) {
            if (this.$attrs.onIconClick) this.$emit('iconClick', this.inputValue);
            else if (this.type === 'password') this.togglePassword();
            else if (this.type === 'date') this.input.showPicker();
        },
    },
    watch: {
        ['$props.modelValue']: {
            immediate: false,
            handler(value = '') {
              this.inputValue = value;
            }
        },
        ['$props.value']: {
            immediate: false,
            handler(value = '') {
                this.inputValue = value;
                this.errorHidden = true;
            }
        },
        ['$props.error']: {
            immediate: false,
            handler(value = '') {
                console.log('ERR', value);
                this.errorHidden = !value;
            }
        },
    },
    computed: {
        iconClickable() {
            return this.$attrs.onIconClick
                || this.type === 'password'
                || this.type === 'date';
        },
        isDisabled() {
            return this.disabled !== null && this.disabled !== false;
        },
        isReadonly() {
            return this.readonly !== null && this.readonly !== false;
        },
        input() {
            return this.$refs.input;
        },
    }
}
</script>
