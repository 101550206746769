import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import VueScrollReveal from 'vue-scroll-reveal'

import spinner from '@/components/spinner.vue';
import IconSvg from '@/components/icons/IconSvg.vue';
import AmplifyVue from '@aws-amplify/ui-vue';
import { Auth } from 'aws-amplify';
import awsExports from './aws-exports';
const aws_exports = require('./aws-exports').default;
import '@aws-amplify/ui-vue/styles.css'; // import amplify styles

const app = createApp(App);

Auth.configure(awsExports); // configure Amplify on the client so that you can use it to interact with your backend services.

app.use(AmplifyVue); // aws account

app.use(store).use(router); // $store & $router



// Provide axios instance to the $client module
// for use in vuex actions (you can get it with rootGetters.$client)
store.registerModule('$client', {namespaced: true, getters: {axios: () => client}});

// errors handler
app.config.errorHandler = (error, instance, info) => {
  console.error('[Vue error]', error, instance, info);
  throw new Error(`${error}`);
};

// global components
app.component('IconSvg', IconSvg);
app.component('spinner', spinner);

// mongodb connection
app.config.globalProperties.$mongoClientPromise = null;

app.mount('#app');
