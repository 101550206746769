<style lang="scss">
.BlockTitle {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 .75em;

  &__link {
    display: inline-block;
    background-color: antiquewhite;
    padding: .5rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    color: $accent;

    .icon {
      margin-left: .25em;
      width: 14px;
      height: 14px;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }

  }
}
</style>

<template>
<h3 class="BlockTitle">
  <span v-text="text"/>
  <router-link class="BlockTitle__link" v-if="route"
    :to="route">
    <span v-text="link"/>
    <icon-svg symbol="arrow-top-right"/>
  </router-link>
</h3>
</template>

<script>
import { RouterLocation } from 'vue-router';
/** @typedef {import('@/types').Api.Dashboard} DashboardData */
export default {
  name: 'BlockTitle',
  props: {
    text: String,
    link: String,
    /** @type {import('vue').PropType<RouterLocation>} */
    route: {type: Object},
  },
}
</script>
