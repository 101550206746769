<style lang="scss" src="./styles.scss" />
<script>
  import Header from '@/components/Header.vue'

  export default ({
    name: 'Sup',
    components: {
      Header
    }
  })
</script>

<template>
  <Header />
  <main>


    <h2> Support fundamental research in the fight against aging</h2>
    <br>



    <section>
      <p>Longevity InTime BioTech calls for helping the scientific communities of all countries in the world related to
        aging research, whose goal is to significantly extend life (120+).</p>
      <p>The paradox is that the average cost of research can be no more than the cost of an apartment in the center of
        the European capital, however, thousands of apartments are bought daily, but it takes 0 to spend on aging
        research that could extend the lives of apartment buyers themselves.</p>
      <p>Here are some studies that require investment. We will replenish this list. You can contact the authors of the
        research groups directly or
      <ol>
        <br>
        <li>Analysis of many scientific publications on the topic of cell aging has led experts to conclude that it is
          closely associated with translation impairments. (Recall that translation is the process of protein synthesis
          by a matrix from RNA, which, in turn, is copied from DNA). However, the mechanisms of this relationship have
          not yet been clarified.</li><br>
        <li>The older a person is, the more divisions his cells have gone through and the more errors have accumulated
          in their DNA. Therefore, the number of defective proteins also increases with age. Is this process one of the
          aging mechanisms? How exactly does it lead to the transformation of a cell into a more "older" one? Is it
          possible to slow down or even stop this "accumulation of errors"? This is a little-studied question.</li><br>
        <li>So, in a number of studies it was shown that blocking individual stages of translation or the intracellular
          signaling pathways that trigger it prolongs the life of a wide variety of organisms, from yeast to mammals.
          This gives hope that someday in the near future protein synthesis modulators will appear that will have the
          necessary selectivity and help humanity to prolong an active and healthy period of life without any side
          effects.</li><br>
        <li>Studies of the CD36 gene - "the main age gene".</li>
      </ol>
      </p>
    </section>
  </main>
</template>