<style lang="scss">
.InputSelect {
  height: 2.5em;
  font: inherit;
  border: none;
  border-radius: .25em;
  background: $accent-lighten;
  padding: 0.0625em 0.5em 0;
  outline-color: $accent;
  outline-offset: -2px;
}
</style>

<template>
<select class="InputSelect"
  @input='inputHandler'
  :value='modelValue'>
  <slot/>
</select>
</template>

<script>
/**
 * InputSelect component for rendering a select input.
 *
 * @component
 * @example
 * // Usage in a Vue template:
 * <InputSelect v-model="selectedValue" />
 *
 * @props {String|Number} modelValue - The current value of the select input.
 *
 * @emits update:modelValue - Emitted when the select input value changes. The payload is the new selected value.
 *
 * @param {String|Number} modelValue - The current value of the select input.
 * @method inputHandler - Event handler for the input change event.
 * @param {Event} event - The input change event object.
 */

export default {
  name: 'InputSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: {type: [String, Number], default: ''},
  },
  methods: {
    inputHandler(event) {
      const {value, options, selectedIndex} = event.srcElement;
      const label = options[selectedIndex].text || '';
      this.$emit('update:modelValue', value);
    }
  },
}
</script>