<template>
	<div class="AppRoot" :class="{ 'global-theme': true }">
		<Header></Header>
		<AuthComponent v-show="this.$store.state.isAuthOpen" />

		<div class="AppRoot--wrapper">
			<!-- <AppHeader /> -->
			<!-- <AuthComponent v-if="this.$store.state.isAuthOpen" /> -->
			<!-- <Auth v-if="!$store.getters.username"/> -->
			<router-view />
			<Footer v-if="!isHidden"></Footer>
		</div>
		<LangModal v-show="openLangModal" style="transform: unset" />
		<MenuModal v-show="openMenuModal" />
	</div>
</template>

<script>
import AuthComponent from "@/components/Auth/Auth.vue";
import MenuModal from "@/components/MenuModal.vue";
import LangModal from "@/components/LangModal.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Auth } from "aws-amplify";
import { scrollCheck } from "@/utils/scrollChecker"

export default {
  name: "App",
  components: {
    Header,
    Footer,
    LangModal,
    AuthComponent,
    // AppHeader,
    MenuModal
  },
  data() {
    return {
      authOverlay: false,
      openLangModal: this.$route.query['open-lang'] === 'true',
      openMenuModal: this.$route.query['open-menu'] === 'true',
      user: {}
    };
  },
  watch: {
    '$route.query.open-lang' () {
      this.openLangModal = (this.$route.query['open-lang'] === 'true')
		  scrollCheck(this.$route.query, this.$store.state.isMobile);
    },
    '$route.query.open-menu' () {
      this.openMenuModal = (this.$route.query['open-menu'] === 'true')
		  scrollCheck(this.$route.query, this.$store.state.isMobile);
    }
  },
  computed:{
	isHidden(){
		return 0
		// this.$route.path in this.$store.state.routeHidesft

	}
  },
  beforeMount() {
		// this.$store
		//   .dispatch("user/initialize")
		//   .then(() => console.log("store.user initialized"));
		(async () => {
			try {
			  let authe = await Auth.currentAuthenticatedUser();
			  this.user = authe;
				console.log("store.user initialized", authe);
			} catch (error) {
				console.log("object");
			}
		})();
	},
  async mounted() {
    try {
    const data = await Auth.currentAuthenticatedUser({
    //   // Optional, By default is false. If set to true,
    //   // this call will send a request to Cognito to get the latest user data
      bypassCache: false,
    })
    // this.$store.commit("user/profile", data.attributes);
    } catch {}

  },
  inject: {


  },
};
</script>

<style lang="scss">
@import "./colors.scss";
@import "./mixins.scss";

.AppRoot {
	&--wrapper {
		padding-top: 150px;
		transform-origin: 0 0;
		transform: scale(0.8);
		width: 125vw;
		height: 100vh;
		scroll-behavior: smooth;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#app {
	overflow-x: clip;
	scroll-behavior: smooth;
	font-family: DM Sans, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

nav {
	padding: 30px;
}

a {
	font-weight: bold;
	color: #2c3e50;
}

.button-back {
	padding-left: 5%;
	padding-top: 20px;
	display: flex;
	align-items: center;
	gap: 15px;
}

@media screen and (max-width:760px){
	.AppRoot{
		&--wrapper{
			padding-top: 80px;
		}
	}
}
</style>
