<template>
	<section class="leaflet">
		<div class="lp__slogan">
			<span class="accent--gradient"> Your </span>
			<span class="data-block">
				<img src="@/assets/img/svg/data_text.svg" class="data-block__img" />

				<div class="data-block__sources" id="data-block__source">
					{{ currentDataSource }}
				</div>
			</span>
			<br />
			<span class="dark-blue block">Can </span>
			<span class="accent--gradient"> Cure You </span>
		</div>
		<h2 class="leaflet__title">Want to live actively longer?</h2>
		<p class="leaflet__subtitle">Scan this code to learn how</p>
		<a :href="qrCodeLink" target="_blank" class="leaflet__link">
			<qrcode-vue :value="qrCodeLink" class="leaflet__img" style="height: 100%; width: 100%" />
		</a>
		<span class="leaflet__span">Longevity InTime AI Health CheckUp</span>
	</section>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
	name: "Leaflet",
	components: {
		QrcodeVue,
	},
	data() {
		return {
			qrCodeLink: "",
			currentDataSource: "Sample Data Source",
		};
	},
	methods: {
		getUserQrCode() {
			const userId = this.generateUniqueId();
			this.qrCodeLink = `${window.location.origin}/leaflet/${userId}`;
		},
		generateUniqueId() {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
				const r = (Math.random() * 16) | 0,
					v = c === "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		},
	},
	created() {
		this.getUserQrCode();
	},
};
</script>

<style lang="scss" scoped>
@mixin mobile {
	@media (max-width: 600px) {
		@content;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "DM Sans";
}

body {
	margin: 0px !important;
}

.global-theme {
	--accent: #3683fc;
	--background: #ffffff;
	--masstrich-blue: #0c1e3c;
	--indigo: #21355c;
	--water: #cde0fe;
	--blue-starlight: #fafcff;
	--green: #22db6c;
	--green-alpha18: rgba(34, 219, 108, 0.18);
	--green-light: #70ffb8;
	--green-light-alpha18: rgba(112, 255, 184, 0.18);
	--red: #fc5a5a;
	--red-alpha18: rgba(252, 90, 90, 0.18);
	--yellow: #f0c105;
	--yellow-alpha18: rgba(240, 193, 5, 0.18);
	--pure-white: #ffffff;
	--white: #f1f1f1;
	--grey00: #f2f3f4;
	--grey0: #e7e9ec;
	--grey: #909ba6;
	--grey2: #707d8b;
	--grey3: #55606a;
	--grey4: #3b424a;
	--grey5: #212529;
	--grey6: #070808;
}
.leaflet {
	width: 80%;
	margin: 0 auto;
}

.lp__slogan {
	max-width: 48%;
	margin: 0 auto;
	font-size: 9vw;
	line-height: 11vw;
	font-weight: 500;
}

.leaflet__title {
	text-align: center;
	font-size: 5vw;
	line-height: 6vw;
	margin-top: 30px;
}

.leaflet__subtitle {
	text-align: center;
}

.leaflet__link {
	width: 300px;
	height: 300px;
	display: flex;
	justify-content: center;
	margin: 10px auto 30px;
}

.leaflet__img {
	width: 100%;
	height: 100%;
	margin: 20px auto;
	justify-content: center;
}

.leaflet__span {
	text-align: center;
	display: flex;
	justify-content: center;
	font-size: 30px;
}

.dark-blue {
	color: #0c1e3c;
}

.block {
	margin-right: 30px;
}

.accent--gradient {
	background: linear-gradient(105.24deg, #3683fc -28.95%, #21355c 260.08%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.data-block {
	vertical-align: middle;
	position: relative;
	width: 20vw;
	display: inline-block;

	@include mobile {
		width: 38vw;
	}

	img {
		width: 100%;
		margin-bottom: 5px;
	}

	&__sources {
		list-style: none;
		padding: 0;
		position: absolute;
		bottom: 5%;
		left: 4%;
		height: 35px;
		width: 100%;
		font-weight: 500;
		font-size: 36px;
		line-height: 47px;
		color: $accent;
		z-index: 20;
		animation: fading 1s ease;

		@include mobile {
			display: none;
		}
	}
}

span {
	animation: slidingUp 0.3s ease-out 1 both;
	display: inline-block;

	&:nth-child(1) {
		animation-delay: 0s;
		margin-right: 40px;
	}
	&:nth-child(2) {
		animation-delay: 0.2s;
	}
	&:nth-child(3) {
		animation-delay: 0.4s;
	}
	&:nth-child(4) {
		animation-delay: 0.6s;
	}
}

.data-block__sources {
	box-sizing: border-box;
	list-style: none;
	padding: 5px;
	bottom: 5%;
	left: 4%;
	min-height: 35px;
	width: 100%;
	font-weight: 500;
	font-size: 36px;
	line-height: 47px;
	color: #3683fc;
	z-index: 20;
}

.lp__slogan span:nth-child(1) {
	animation-delay: 0s;
	margin-right: 40px;
}
.lp__slogan span:nth-child(2) {
	animation-delay: 0.2s;
}
.lp__slogan span:nth-child(3) {
	animation-delay: 0.4s;
}
.lp__slogan span:nth-child(4) {
	animation-delay: 0.6s;
}
.lp__slogan span {
	animation: slidingUp 0.3s ease-out 1 both;
	display: inline-block;
}
@media (max-width: 767px) {
	.lp-wrapper .lp__slogan .data-block__sources {
		display: none;
	}

	.data-block__sources {
		font-size: 20px;
		line-height: 22px;
		padding-top: 25px;
	}
}
</style>
