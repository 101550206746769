<template>
<div class="InputCode" @click.stop="focus()">
  <input v-for="k in 6"
    maxlength="1"
    :name="`input-code-${k}`"
    type="number"
    ref="input"
    placeholder=" "
    @input="atInput(k - 1, $event)"/>

</div>
</template>

<script>
export default {
  name: 'InputCode',
  methods: {
    async atInput(n, event) {
      const input = this.$refs.input[n];
      const value = input.value || '';
      if (value.length > 1) input.value = value[value.length - 1];
      else if (!value.length) return this.value = '';

      const next = this.$refs.input[n + 1];
      if (next) next.focus();
      else input.blur();
    },
    focus() {
      this.$refs.input[0].focus();
      // console.log(this.value, this.getCodeValue());
    },
    getCodeValue() {
      const values = this.$refs.input.map(inp => inp.value || '');
      return values.join('');
    },
  },
}
</script>

<style lang="scss">
.InputCode {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font: 1.5rem/1 $font-sans;

  & > input {
    font: inherit;
    pointer-events: none;
    box-sizing: border-box;
    background: var(--accent-lighten);
    border: 1px solid transparent;

    text-align: center;
    width: 48px;
    height: 48px;
    padding: 0;
    outline: 0;
    margin: 0 0 0 .75rem;
    border-radius: .375rem;

    transition: border .25s ease 0s;

    &:first-of-type {margin-left: 0}
    &:nth-child(4) {margin-left: 2.5rem}

    input[type=number] {-moz-appearance: textfield}
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:placeholder-shown {
      box-shadow: inset 0 -2px 0 0px var(--accent);
    }

    &:active,
    &:focus {
      border-color: var(--accent);
      box-shadow: none;
    }
  }
}
</style>

