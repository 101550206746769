<style lang="scss">

.profile {
	padding: 10vw;
	padding-top: 106px;
	&__signout {
		background-color: transparent;
		border: none;
		cursor: pointer;
	}
}
.amplify-authenticator__column {
	gap: 48px !important;
}

@media (min-width: 30rem) {
	[data-amplify-authenticator] [data-amplify-container] {
		width: auto
	}
}


[data-amplify-authenticator] [data-amplify-form] {
	padding: 0 !important;
	padding-top: 75px !important;
}

[data-amplify-authenticator] [data-amplify-router] {
	max-width: 730px;
	width: 103vw;
	height: 930px;
	flex-shrink: 0;
	// padding: 0 115px;
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
}

.amplify-authenticator__tabs {
	display: none !important;
}

.AuthorizerLogin {
	&__lost {
		margin: 0.25em 0.25em 0 0;
		font-size: 1rem;
		text-align: right;

		a {
			color: black;
			text-decoration: underline;
		}
	}

	.spinner {
		width: 2.5rem;
		height: 2.5rem;
		margin: 0.25rem 0 0;
		color: inherit;
	}

	.OauthButtons {
		flex: 1 1 auto;
		margin-right: 2rem;
	}
}
</style>

<template>
	<!-- <authenticator :services="services" :social-providers="['google']"  :form-fields="formFields" :sign-up-config="signUpConfig" variation="modal"> -->
	<authenticator :services="services" :social-providers="['google']" :form-fields="formFields"
		:sign-up-config="signUpConfig">

		<!-- :social-providers="['apple', 'facebook', 'google']" -->

		<template v-slot:sign-in-header>
			<div style="padding-top: 156px">
				<span style="
					color: var(--masstrich-blue);
					font-size: 64px;
					font-style: normal;
					font-weight: 500;
					line-height: normal; ">
					Login
				</span>
			</div>
		</template>


		<template v-slot:confirm-sign-in-header>
			<h3 class="amplify-heading" style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)">
				Enter Information:
			</h3>
		</template>


		<template v-slot:sign-up-header>
			<span style="
	          color: var(--masstrich-blue);
	          font-size: 64px;
	          font-style: normal;
	          font-weight: 500;
	          line-height: normal;
	        ">
				Sign up
			</span>
			<br />

			<span style="
	          color: var(--indigo);
	          font-size: 24px;
	          font-style: normal;
	          font-weight: 400;
	          line-height: normal;
	        ">
				By creating an account you agree with our
				<router-link to="/policies/terms-of-service" style="
	            color: var(--accent);
	            font-size: 24px;
	            font-style: normal;
	            font-weight: 400;
	            line-height: normal;
	          ">Tems of Service</router-link>
				and
				<router-link to="/policies/privacy-policy" style="
	            color: var(--accent);
	            font-size: 24px;
	            font-style: normal;
	            font-weight: 400;
	            line-height: normal;
	          ">Privacy Policy</router-link>
			</span>
		</template>
		<template v-slot:sign-in-footer>
			<span @click="toResetPassword" style="color: var(--grey4);
	          text-align: right;
	          font-size: 14px;
	          font-style: normal;
	          font-weight: 400;
	          line-height: normal;
	          text-decoration-line: underline;
	          position: absolute;
	          margin-top: -88px;
	          margin-left: 361px;
	          cursor: pointer">
				Forgotten password?
			</span>
			<div style="text-align: center;
	    padding-top: 55px;">
				<span style="
	            color: var(--indigo);
	            font-size: 24px;
	            font-style: normal;
	            font-weight: 500;
	            line-height: normal;
	          ">No account?</span><span @click="toSignUp" style="
	            color: var(--accent);
	            font-size: 24px;
	            font-style: normal;
	            font-weight: 500;
	            line-height: normal;
	            cursor: pointer;
	          ">
					Sign up</span>
			</div>
		</template>
		<template v-slot:sign-up-footer>
			<div style="text-align: center;
    padding-top: 55px;">
				<span style="
            color: var(--masstrich-blue);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          ">Already have an account?</span>
				<br><span @click="toSignIn" style="
            color: var(--accent);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
          ">
					Sign in</span>
			</div>
		</template>


		<template v-slot:reset-password-header>
			<span style="
          color: var(--masstrich-blue);
          font-size: 64px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        ">
				Reset Password
			</span>
			<span style="
          color: var(--indigo);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: -40px;
        ">
				Enter your email or phone number and we will send you a verification code
			</span>
		</template>
		<template v-slot="{ user, signOut }">
			<div class="profile">
				<h1>Hello {{ user.attributes?.email || signInUserSession?.idToken?.payload.email }}!</h1>
				<button class="profile__signout" @click="signOut(); save(0)">Sign Out</button>
				<!-- <button @click="save(user)" style="background-color: red; color: white; font-size: 110%;">click here to
					continue</button> -->
			</div>
		</template>

	</authenticator>
</template>
<script setup>
import { Amplify, Auth, Hub } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-vue";
import { v4 as uuidv4 } from "uuid";
import store from "@/store";
import router from "@/store";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
// import { Hub } from "aws-amplify/utils";
// import { signInWithRedirect, getCurrentUser } from "aws-amplify/auth";
import awsExports from '@/aws-exports';

console.log(awsExports)
Auth.configure({ ...awsExports }); // configure Amplify on the client so that you can use it to interact with your backend services.
// console.log(Auth._config)
// window.Authe = Auth

Hub.listen("auth", async ({ payload }) => {
	switch (payload.event) {
		case "signInWithRedirect":
			const user = await getCurrentUser();
			console.log(user.username);
			if (window.opener) {
				window.close();
				window.opener.postMessage('social-login');
				}
			break;
		case "signInWithRedirect_failure":
			// handle sign in failure

			console.log("state");
			break;
		case "customOAuthState":
			const state = payload.data; // this will be customState provided on signInWithRedirect function
			console.log(state);
			break;
	}
});
window.addEventListener("message", (p)=>{
	console.log(p.data)
	// alert(p.data)
	if(window.windowProxy&&p.data=="i"){
		window.windowProxy.close()
		window.location.reload()
	}
})

function handleSignInClick(customState) {
	// signInWithRedirect({
	// 	provider: "Google",
	// 	customState
	// });
	// signInWithRedirect();
	Auth.federatedSignIn({
		provider: CognitoHostedUIIdentityProvider.Google
	});
}

window.f = () => {
	handleSignInClick()
}

const emailRegex = /^[\w\.-]+@[\w\.-]+\.\w+$/;
const phoneRegex = /^\+\d{10,12}$/;
var authe = useAuthenticator();
const { toResetPassword, toSignUp, toSignIn } = authe;
const signUpConfig = {
	hideAllDefaults: true,
	// signUpFields: [ { key: 'email' }, { key: 'password' } ]
};
const services = {
	async handleSignUp(formData) {
		let { username, password, attributes } = formData;
		console.log(username.replace(/\W/gi, "_"))

		if (emailRegex.test(username)) {
			attributes.email = username;
			attributes.updated_at = new Date().valueOf().toString();
			const data = await Auth.signUp({
				username: username.replace(/\W/gi, "_"),
				password,
				attributes,
				autoSignIn: {
					enabled: true,
				},
			});
			return data
		} else if (phoneRegex.test(username)) {
			console.log(123)
			attributes.phone_number = username;
			attributes.updated_at = new Date().valueOf().toString();
			const data = await Auth.signUp({
				username: username.replace(/^[\w&.-]+$/gi, "_"),
				// username: username,
				password,
				attributes,
				autoSignIn: {
					enabled: true,
				},
			});
			return data
		}
	},

	async handleSignInClick(customState) {
		signInWithRedirect({
			provider: "Google",
			customState
		});
	},
	async handleSignIn(formData) {
		let { username, password, attributes } = formData;

		const user = await Auth.signIn({
			username,
			password,
			attributes,
		});
		if (!user.challengeName) {
			return user;
		}
	},
	async federatedSignIn(formData) {
		let { username, password, attributes } = formData;


		const user = await Auth.federatedSignIn({
			provider: CognitoHostedUIIdentityProvider.Google
		});

		if (!user.challengeName) {
			return user;
		}
	},
	async oauthSignIn(formData) {
		let { username, password, attributes } = formData;


		const user = await Auth.federatedSignIn({
			provider: CognitoHostedUIIdentityProvider.Google
		});

		if (!user.challengeName) {
			return user;
		}
	},

};
const formFields = {
	signIn: {
		username: {
			labelHidden: true,
			placeholder: "Email Or Phone",
		},
		password: {
			labelHidden: true,
			placeholder: "Password",
		},
	},
	signUp: {
		username: {
			labelHidden: true,
			placeholder: "Email Or Phone"
		},
		password: {
			labelHidden: true,
			placeholder: "Password",
		},
		confirm_password: {
			labelHidden: true,
			label: "Confirm Password:",
		},
	},
	confirmSignIn: {
		confirmation_code: {
			labelHidden: true,
			placeholder: "Enter your Confirmation Code:",
			isRequired: false,
		},
	},
	resetPassword: {
		username: {
			labelHidden: true,
			placeholder: "Email Or Phone"
		},
	},
};
if (!(authe?.user)) {
	let i = setInterval(() => {
		if (authe?.authStatus == "authenticated") {
			console.log("welcome");
			console.log(authe);
			clearInterval(i)
			console.log(authe.user);
			store.state.user = authe.user;
			console.log("store.user initialized", store.state.user)
			// let a = alert("welcome")
			if (authe.user && location.search.lastIndexOf("next") > 0) {

				setTimeout(() => {
					console.log("object");

					location.replace(new URL(location).searchParams.get("next"))
					// router.push({name: 'ui' , path:,params:{handle:"product"} })

				}, 500);
			}
		}
	}, 300);
}
</script>
<script>

export default {
	name: "Login",
	emits: ["update:modelValue", "clickForgotten"],
	inject: {
		$client: {
			from: "client",
		},
	},
	props: {
		signup: { type: Boolean, default: false },
		modelValue: { type: String, default: "" },
	},
	data() {
		return {
			loginError: "",
			passwordValue: "",
			pending: false,
			user: {}
		};
	},
	mounted() { document.title = "login" },

	methods: {

		save(user) {
			if (user === 0) {
			// Clear user data from Vuex store
			// store.state.user = 0;
			store.commit("user", 0);
			store.commit("cartC", {});
			this.$router.push('/');
			} else {
			console.log(user);
			console.log("store.user initialized", store.state.user)
			store.state.user = user;
			store.commit("user", user);
			this.$router.go(-1);
			}
		},
	},
	computed: {

	},

};

</script>
