<!-- 
  author: Elena Svizeva
  updated: 22 Jun 2022 21:48 GMT>
  name: RadioButton 
-->

<template>
  <div class="radio-button-group">
    <label v-for="(option, i) of options"
           :key="i" 
           class="radio"
    >
      <span :for="option" class="option">{{ option }}</span>
      <input type="radio" 
             name="radio-input" 
             :value="option" 
             :id="option" 
             :disabled="disabled"
             @change="onChange" />
      <span class="checkmark"></span>
    </label>
  </div>
</template> 

<script>
export default {
  name: 'RadioButton',
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      value: []
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../colors.scss';

/* custom radio button */
.radio {
  display: block;
  position: relative;
  margin: 10px;
  height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* hide default HTML radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .option {
    margin-left: 35px;
    font-size: 16px;
    line-height: 24px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #3683FC2E;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-out .3s;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 4px;
      background-color: #3683FC2E;
      border-radius: 50%;
      transition: all ease .3s;
    }
  }
}

.radio input {

  &:hover ~ .checkmark {
    border-color: $accent;
  }

  &:checked ~ .checkmark:after {
    width: 16px;
    height: 16px;
    background: $accent;
  }

  &:disabled ~ .checkmark {
    background-color: #3683FC1A;
    
    &:after {
      width: 16px;
      height: 16px;
    }
  }
  &:disabled:hover ~ .checkmark {
    border-color: transparent!important;
  }
}

</style>