<style lang="scss">
.DashboardQuickTune {
  $Hug: 47px;
  ul {
    @include list-reset();
    display: flex;
    flex-flow: row nowrap;
    gap: 2.25rem;
    li {
      background: wheat;
    }

    sup {
      display: block;
      font-size: 100%;
    }

    span[data-units] {
      white-space: nowrap;
      display: inline-block;
      box-sizing: border-box;
      background: $accent-lighten;
      border-radius: 6px;
      line-height: 1;
      height: $Hug;
      min-width: 75px;
      text-align: center;
      padding: 12px 18px 0;
      &:not([data-units=""])::after {
        content: attr(data-units);
        display: inline-block;
        vertical-align: middle;
        font-size: .875rem;
        color: var(--grey2);
        margin-left: 1rem;
      }
    }

    & > li {
      & > div {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
      }

      & > sub {
        color: green;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        small {font-size: 83.33%}
      }
    }

  }
}
</style>

<template>
<div class="DashboardQuickTune">
  <ul>
    <li>
      <sup>Weight</sup>
      <span data-units="Kg">
        {{dashboard.weight}}
      </span>
    </li>
    <li>
      <sup>Heart rate</sup>
      <span data-units="BPM">
        {{dashboard.heart_rate_alone}}
      </span>
    </li>
    <li>
      <sup>Blood pressure</sup>
      <div>
        <span data-units>{{dashboard.blood_pressure_sys}}</span> /
        <span data-units>{{dashboard.blood_pressure_dia}}</span>
      </div>
      <sub>
        <small>systolic</small>
        <small>diastolic</small>
      </sub>
    </li>
    <li>
      <sup>Cholesterol</sup>
      <span data-units="mmol/L">
        {{dashboard.cholesterol || '--'}}
      </span>
    </li>
    <li>
      <sup>Glucose</sup>
      <span data-units="mmol/L">
        {{dashboard.glucose || '--'}}
      </span>
    </li>
  </ul>
</div>
</template>

<script>
/** @typedef {import('@/types').Api.Dashboard} DashboardData */
export default {
  name: 'DashboardQuickTune',
  props: {
    /** @type {import('vue').PropType<DashboardData>} */
    dashboard: {type: Object, required: true},
  },
}
</script>
