<template>
<i class="UserAvatar">
  <icon-svg v-if="!image" symbol="avatar"/>
  <img v-else :src="image" :alt="$store.getters.username"/>
</i>
</template>

<script>
import {mapState} from 'vuex';
import {UserState} from '@/store/user/types';
export default {
  name: 'UserAvatar',
  computed: {
    ...mapState('user', {
      /** @type {(state: UserState) => string} */
      image: state => state.profile?.image || '',
    }),
  },
}
</script>

<style lang="scss">
.UserAvatar {
  flex: 0 0 auto;
  display: inline-block;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  & > img,
  & > .icon {
    display: block;
    height: 100%;
    width: 100%;
  }
  & > img {
    object-fit: scale-down;
  }
  & > .icon {
    box-sizing: border-box;
    color: $accent-lighten;
    background: $accent;
    padding: 8%;
  }
}
</style>
