export function scrollCheck (query, isMobile) {
  let body = document.getElementsByTagName("body")[0];
  if ((query['open-lang'] || query['open-menu']) && !isMobile ) {
    body.style.overflow = "hidden";
  } else if ((query['open-lang'] || query['open-menu']) && isMobile) {
    body.classList.add("mobile-overflow");
  } else {
    body.style.overflow = "unset";
    body.style['overflow-x'] = "hidden";
    body.classList.remove("mobile-overflow");
  }
}