<style lang="scss" src="./styles.scss" />
<script>
    import Header from '@/components/Header.vue'

    export default ({
        name: 'Vac',
        components: {
            Header
        }
    })
</script>

<template>
    <Header />
    <main class="main">
        <h1>Work with Us</h1>
        <br>
        <br>
        <p>Longevity InTime BioTech (AI backed severe diseases real-time tracker) is permanently inviting:</p>
        <br>

        <ul class="vacc-1">
            <li>HR, Lawyer Accountant</li>
            <li>AI, ML, Computer Vision engineers </li>
            <li>Data, Big data scientists </li>
            <li>Marketing managers</li>
            <li>SMM, SEO</li>
            <li>Sales executives</li>
            <li>Flutter developers</li>
            <li> DevOps, CTO, Tech writer</li>
            <li>Blockchain, crypto developers</li>
            <li>Designers</li>
            <li>Corporate assistants</li>
        </ul>
        <br>
        <p>
            Thank you for your interest in Longevity InTime.
        </p>
        <p>We need strong professionals with over 3 years experience to boost our longevity products ai,ed to save life
            in this life.</p>
        <p>To join our 40 members team from 35 countries, we request to make a free 2 quick onboarding tests (15-30 min
            each), to get you to know about our longevity products aimed to save lives and 1 program test.</p>
        <p>If you are ok with it, please message to our Founder Oleg Teterin in Telegram (make the account in Telegram
            if needed) with 5 answers to the questions below:</p>
        <p>1. Your CV <br>
            2. Primary skill set description written in the message, even if they mentioned in the CV <br>
            3. Additional skill set description <br>
            4. Acknowledgement for making free test <br>
            5. Your skill level. If you are lower than we look for or have less years experience, please briefly assure
            us that you are a good fit</p>
        <p>After all we will look at your marketing test task to see your fit.</p>
        <p>We will start evaluating your reply ONLY if it contains all 5 answers AND ONLY SENT IN TELEHGRAM, apologies
            in advance for this strict rule.
            <br>
            Thanks
        </p>
        <p> Telegram: <a href="https://t.me/InTimeDigitizeMeToLive120">Contact us on Telegram</a></p>
        <p>Please bypass Telegram's barriers for messaging to new users, there is a solution, please Google it. If you
            can't bypass them (and that would be strange, please send the telegram user link to your Telegram and we
            will message you).</p>
        <p>Please note, that if you ask some questions and we didn't reply, that means the answer was already in other
            materials (job description and this message). Due to what we do in the longevity field, we know the real
            price of the person's time and value it more than money, so we kindly ask for the same in return</p>
        <p>We are leaving you corp email of our Founder, just in extra cases</p>
        <p>email: <a href="mailto:teterin@longevityintime.org">Contact us via email</a>
        </p>
    </main>
</template>
