<template>
  <div class="back" @click="$emit('changeEmail')">
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7428 38.107L5.04572 25.41C4.26657 24.6308 4.26657 23.3676 5.04572 22.5884L17.7428 9.89141C18.5219 9.11225 19.7852 9.11225 20.5643 9.89141C21.3435 10.6706 21.3435 11.9338 20.5643 12.713L11.2732 22.0041L42.3691 22.0041L42.3691 25.9944L11.2732 25.9944L20.5643 35.2855C21.3435 36.0646 21.3435 37.3279 20.5643 38.107C19.7852 38.8862 18.5219 38.8862 17.7428 38.107Z" fill="CurrentColor"/>
    </svg>
    <span class="back__text">
      Change email
    </span>
  </div>
  <span class="code__header">Verify your <span class="code__header--marked">identity</span></span>
  <br />
  <span
    >We have sent you a message with 6-digit code, or use the code from yout Authenticator App.
  </span>
  <div class="code__input">
    <input-code ref="input" />
  </div>
  <div class="code__submit">
    <button class="button code__submit--button" @click.prevent="$emit('codeSubmit', codeVal)">{{buttonText}}</button>
  </div>
  <div class="code__footer">
    <span class="code__footer--button" @click.prevent="$emit('resendCode')">Send it again</span>
      <br />
      Be quick! the code expires in 12 hours
  </div>
</template>
<script>
import InputCode from "@/components/input-code/InputCode.vue";

export default {
	name: "CodeVerificator",

  components: {
		InputCode,
	},

  data () {
    return {
      buttonText: ""
    }
  },
  mounted () {
    this.buttonText = JSON.parse(localStorage.getItem("authComp"))?.isForggotenPass ? "Change" : "Login"
  },
  computed: {
    codeVal () {
      return this?.$refs?.input?.getCodeValue()
    }
  },
}
</script>


<style scoped lang="scss">
.back {
  display: flex;
  align-items: center;
  gap: 9px;
  color: var(--masstrich-blue);

  &__text {
    font-family: DM Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }
}

.code {

  &__header {
    font-family: DM Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--masstrich-blue);

    &--marked {
      font-family: DM Sans;
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: var(--accent);
    }
  }

  &__input {
    padding-top: 89px;
  }

  &__submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &--button {
      display: flex;
      width: 136px;
      height: 47px;
      padding: 8px 81px;
      margin-top: 108px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: var(--white);
      border-radius: 140px;
      background: var(--accent);
      border-width: 0px;
      box-shadow: 6px 6px 36px 0px rgb(0 0 0 / 65%);
    }
  }

  &__footer {
    color: var(--indigo);
    padding-top: 93px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: normal;

    &--button {
      color: var(--accent);
      text-align: center;
      font-family: DM Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}
</style>
