<style lang="scss">
@keyframes hilandero-spin {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes hilandero-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
.hilandero {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    padding: 1px;
    height: 52px;
    width: 52px;
    color: #9ea7ca;
    stroke-width: 5;
    &[hidden] {display: none}
}
.hilandero > svg {
    display: block;
    width: 100%;
    height: 100%;
    animation: hilandero-spin 2s linear infinite;
    & > circle {
        animation: hilandero-dash 1.5s ease-in-out infinite;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-width: inherit;
    }
}
</style>

<template>
<span class="hilandero">
    <svg viewBox="0 0 50 50"><circle cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
</span>
</template>