<template>
	<div class="overlay show language-wrapper">
		<transition name="slide-right">
			<div class="popup popup--lang" id="language-box">
				<div class="flex nav">
					<!-- <InputText :type="text" /> -->
					<button class="button button--close" @click="closeLangBox"></button>
					<button class="button button--lang">En</button>
				</div>
				<div class="radiobuttons-list">
					<RadioButton :options="languages" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import RadioButton from "./UIKit/RadioButton.vue";

export default {
	name: "LangModal",
	components: {
		RadioButton,
	},
	data() {
		return {
			languages: ["English (US)", "Russian"],
			// languages: [
			//     {
			//         name: 'English (US)',
			//         icon: ''
			//     },
			//     {
			//         name: 'Russian',
			//         icon: ''
			//     }
			// ],
		};
	},
	methods: {
		closeLangBox() {
			this.$router.push({ query: { ...this.$route.query, "open-lang": undefined } });
		},
	},
};
</script>

<style lang="scss">
@import "../colors.scss";
@import "../mixins.scss";

// .overlay {
//     position: fixed;
//     width: 100vw;
//     height: 100vh;
//     z-index: 45;
//     top: 0;
//     left: 0;
// }

#language-box {
	.radiobuttons-list {
		margin-top: 13%;

		.radio {
			margin-bottom: 24px;

			span {
				font-size: 24px;
				line-height: 29px;
			}
			.checkmark {
				right: 0;
				left: auto;
			}
		}
	}
}
</style>
