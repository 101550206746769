<template>
<div class="FormUserProfile">
  <InputText
    label="Username"
    @input="atUsernameInput"
    v-model="username"/>

  <InputText
    label="Email"
    v-model="modelValue.email"/>

  <InputSelect v-model="modelValue.gender">
    <option value="3" disabled>Specify gender</option>
    <option v-for="c in genders" :key="c.id" :value="c.id">{{c.value}}</option>
  </InputSelect>

  <InputText
    type="date"
    label="Birth date"
    v-model="modelValue.birth_date"/>

  <InputSelect v-model="modelValue.country">
    <option value="" disabled>Выберите страну</option>
    <option v-for="c in countries" :key="c.id" :value="c.id">{{c.value}}</option>
  </InputSelect>

</div>

</template>

<script>
import InputText from '@/components/input-text/InputText.vue';
import InputSelect from '@/components/input-select/InputSelect.vue';
/**
 * @typedef {object} FieldsetValues
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} phone
 * @property {string} birth_date
 * @property {string} gender
 * @property {string} country
 */

export default {
  name: 'FormUserProfile',
  components: {InputText, InputSelect},
  props: {
    /** @type {import('vue').PropType<FieldsetValues>} */
    modelValue: {type: Object, required: true},
  },
  data() {
    return {
      username: '',
      countries: [],
      genders: [],
    };
  },
  watch: {
    modelValue: {
      deep: false,
      immediate: true,
      handler(value, oldValue) {
        // console.log('modelValue changed', {...this.modelValue});
        this.resetValues();
      },
    }
  },
  async mounted() {
    // this.getDataHistory();
    // await this.putResult();
    // await this.getMedCard();
    const {countries, genders} = await this.$store.dispatch('fetchInfo');
    this.countries = countries;

    const defaultKey = genders.findIndex(v => v.id === 3);
    if (defaultKey) genders.splice(defaultKey, 1);
    this.genders = genders;
  },
  methods: {
    resetValues() {
      // const {first_name, last_name} = this.modelValue;
      // this.username = `${first_name || ''} ${last_name || ''}`.trim();
    },
    atUsernameInput(username = '', event) {
      console.log(`username: ${username}`, username);
      const res = /(\S+)\s?(.*)/.exec(username);
      // const first_name = res?.[1] || username || '';
      // const last_name = res?.[2] || '';
      const value = {
        ...this.modelValue,
        // first_name,
        // last_name,
      };
      this.$emit('update:modelValue', value);

      // const [first_name, last_name] = value.split(' ');
    },
  },
}
</script>

<style lang="scss">
.ViewUserProfile {
  .InputText {
    max-width: unset;
    width: 100%;
    margin: 2rem 0;
  }

  .InputSelect {
    width: 100%;
    margin: 2rem 0;
  }

}
</style>
