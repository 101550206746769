<style lang="scss">
.DashboardSuggestions {
  .icon[data-priority] {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    &[data-priority="normal"] {
      padding: 2px;
    }
    &[data-priority="high"] {
      padding: 1px;
    }
  }

  &__legend {
    border: 1px solid $water;
    padding: 1.5rem;
    border-radius: .75rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 2.25rem;
    & > strong {
      font-weight: 500;
      margin-right: auto;
      .icon {
        color: $accent;
        width: 25px;
        height: 17px;
        vertical-align: baseline;
        margin-left: 1rem;
      }
    }
    & > span {
      display: flex;
      align-items: center;
      .icon {margin-right: .5rem}
    }

  }
  &__list {
    @include list-reset;
  }
  &__list > li {
    background: lightgoldenrodyellow;
    position: relative;
    padding: 2.25rem 0 2.25rem 3.5rem;
    border-top: 1px solid $water;
    &:first-of-type {border-top-width: 0}
    & > .icon {
      position: absolute;
      left: 0;
      top: 2.25rem;
      margin: .25rem 0 0 .5rem;
    }
    & > strong {
      display: block;
      font-weight: 400;
      margin: 0 0 .5rem;
    }
    & > p {
      margin: 0;
      font-size: 1.125rem;
      & + p {
        margin-top: 1.5rem;
      }
    }
  }

}
</style>

<template>
<div class="DashboardSuggestions">

  <section-title class="DashboardSuggestions__title"
    text="Suggestions"
    link="View all"
    :route="{name: 'home'}"/>

  <div class="DashboardSuggestions__legend">
    <strong>Priority <icon-svg symbol="burger-down"/></strong>
    <span>
      <icon-svg data-priority="normal" symbol="priority-normal"/>
      Normal
    </span>
    <span>
      <icon-svg data-priority="medium" symbol="priority-medium"/>
      Medium
    </span>
    <span>
      <icon-svg data-priority="high" symbol="priority-high"/>
      High
    </span>
  </div>

  <ul class="DashboardSuggestions__list">
    <li>
      <icon-svg data-priority="normal" symbol="priority-normal"/>
      <strong>Walk more than 8k steps per day</strong>
    </li>
    <li>
      <icon-svg data-priority="high" symbol="priority-high"/>
      <strong>Lower your cholesterol</strong>
      <p>Cholesterol level should be less than 5</p>
      <p>Current: 5,7 mmol/L</p>
    </li>
  </ul>

</div>
</template>

<script>
/** @typedef {import('@/types').Api.Result} SummaryData */
import SectionTitle from './SectionTitle.vue';
export default {
  name: 'DashboardSuggestions',
  components: {SectionTitle},
  inject: {
    $client: {
      from: 'client',
    }
  },
  props: {
    /** @type {import('vue').PropType<DashboardData>} */
    // dashboard: {type: Object, required: true},
  },
  data() {
    return {
      summary: null,
      daysAgo: null,
    };
  },
  async mounted() {
  },
  methods: {
  },
}
</script>
