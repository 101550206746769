<template>
<div class="Badger"
    :class='{
        [`Badger--${type}`]: true,
        [`Badger--${size}`]: !!size,
    }'>

    <div class="Badger__icon" v-if='icon'>
        <icon-svg :symbol='typeIcons[type]'/>
    </div>

    <div class="Badger__text">
        <strong v-if='title' v-text='title'/>
        <div v-if='descr' v-text='descr'/>
    </div>
</div>
</template>

<script>
/**
 * @updated: Sun, 10 Jul 2022 14:18:43 GMT
 * @name: Badge
 *
 * Figma mockup: https://www.figma.com/file/20GUJWT3OckRt1NP76bMve/Intime-Digital?node-id=12757%3A8701
 * $props.size - font & icon & other size. Can be 'large' or 'small' or normal by default.
 * $props.icon - icon can be disabled with 'false' value
 
 * see badge image: ![Figma UI Preview](https://gitlab.com/gl.frontend/app_mvp/-/raw/dprincecoder-main-patch-92028/src/docsImages/Screenshot_from_2023-09-27_17-14-34.png)
 */
export default {
    name: 'Badge',
    inheritAttrs: false,
    data() {
        const typeIcons = {
            suggest: 'asteriskColor',
            warning: 'warning',
            success: 'success',
            danger: 'danger',
            info: 'info',
        };

        return {
            typeIcons,
        };
    },
    props: {
        title: {type: String, default: ''},
        descr: {type: String, default: ''},
        icon: {type: Boolean, default: true}, // icon can be disabled
        size: {type: String, default: null}, // 'large'|'small' (medium by default)
        type: {
            type: String, // 'suggest'|'danger'|'warning'|'success'|'info'
            default: 'info',
            validator(value) {
                const validValues = ['suggest', 'danger', 'warning', 'success', 'info'];
                return validValues.includes(value);
            },
        },
    },
};
</script>

<style lang="scss">
.Badger {
    $self: &;
    & + & {margin-top: 1.5rem}
    cursor: default;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: var(--accent-alpha18);
    border-radius: .75rem;  
    padding: 1.125rem;
    padding-left: 0 !important;
    color: var(--accent-text);
    #{$rootDarkClass} & {color: var(--accent)}

    &::before {
        content: '';
        flex: 0 0 4px;
        align-self: stretch;
        border-radius: 2px;
        background-color: var(--accent);
        margin: 0 2.75rem 0 -2px;
    }

    &__icon {
        flex: 0 0 auto;
        align-self: flex-start;
        padding: 6px;
        width: 54px;
        height: 54px;
        margin: 0 2.5rem 0 0;
        color: var(--accent);
        .icon {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__text {
        & > strong {
            display: block;
            font-size: 133.33%;
            line-height: 1.25;
            font-weight: 400;
        }
        & > div {
            line-height: 1.333;
            &:not(:first-child) {margin-top: .75em}
        }
    }

    &--large {
        padding: 1.5rem;
        font-size: 1.5rem;
        border-radius: .875rem;
        &::before {
            margin-right: 2.125rem;
        }
        #{$self}__icon {
            width: 70px;
            height: 70px;
            margin-right: 2.125rem;
        }
    }

    &--small {
        border-radius: .5rem;
        font-size: .875rem;
        &::before {
            margin-right: 1.5rem;
        }
        #{$self}__text > strong {
            font-size: 122%;
        }
        #{$self}__icon {
            width: 36px;
            height: 36px;
            margin-right: 1.25rem;
        }
    }

    &--success {
        color: var(--green-text);
        background-color: var(--green-alpha18);
        &::before {background-color: var(--green)}
        #{$self}__icon {color: var(--green)}
        #{$rootDarkClass} & {color: var(--green)}
    }

    &--suggest {
        color: var(--orange-text);
        background-color: var(--orange-alpha18, 18%);
        &::before {background-color: var(--orange)}
        #{$self}__icon {color: var(--orange)}
        #{$rootDarkClass} & {color: var(--orange)}
    }

    &--warning {
        color: var(--yellow-text);
        background-color: var(--yellow-alpha18, 18%);
        &::before {background-color: var(--yellow)}
        #{$self}__icon {color: var(--yellow)}
        #{$rootDarkClass} & {color: var(--yellow)}
    }

    &--danger {
        color: var(--red-text);
        background-color: var(--red-alpha18, 18%);
        &::before {background-color: var(--red)}
        #{$self}__icon {color: var(--red)}
        #{$rootDarkClass} & {color: var(--red)}
    }
}
</style>