<template>
<div id="sceneContainer" ref="sceneContainer" @wheel.prevent="handleMouseWheel($event)" class="nav header__menu">
 <template v-for="item in data" :key="item.hash + item.url" >
  <router-link v-if="!item.hash" :to="item.url+item.hash" :class="{'button': true, 'button__active': $route.path === item.url}">{{item.label}}</router-link>
  <a v-else :href="item.url+item.hash" :class="{'button': true, 'button__active': $route.path === item.url}">{{item.label}}</a>
 </template>
 </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      objects: [],
    };
  },
  mounted() {
  },
  methods: {
    handleMouseWheel(event) {
      const delta = event.deltaY;
      const target = this.$refs.sceneContainer.scrollLeft + delta * 0.5
      this.$refs.sceneContainer.scrollTo({left: target, behavior: 'smooth'})
    },
  },

}
</script>
<style scoped lang="scss">
@import "../colors.scss";
@import "../mixins.scss";
.header__menu {
  @include mobile {
    display: flex;
    position: fixed;
    top: 113vh;
    right: 0;
    background: #cde0fe;
    box-shadow: 6px 6px 48px rgba(12, 30, 60, 0.1);
    border-radius: 120px 0 0 120px;
    padding: 8px 24px;
    height: 48px;
  }
}

.nav {
  font-size: 24px;
  line-height: 31px;
  text-transform: transparent;
  min-width: auto;
}

.button,
button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
}

.button {
  font-size: 24px;
  line-height: 31px;
  text-transform: uppercase;
  min-width: auto;
  color: $indigo;

  &__active {
    color: $accent;
    cursor: default;

    &:hover {
      color: $accent;
    }
  }

//   @include mobile {
//     line-height: 2;
//   }

  &:last-child {
    margin-right: 0;
  }
}
</style>
