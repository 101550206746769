<template>
<div class="ViewDashboard">

  <!-- <button @click="putMedCard">DD</button> -->
  <!-- <section class="ViewDashboard__summary">

  </section>

  <section class="ViewDashboard__quicktune">
  </section> -->

  <section class="ViewDashboard__summary">
    <Summary v-if="true"/>
  </section>
  <section class="ViewDashboard__slider">
    <Suggestions/>
  </section>
  <section class="ViewDashboard__quicktune">
    <section-title
      text="Quick tune"
      link="My biomarkers"/>
    <QuickTune :dashboard="dashboard" v-if="dashboard"/>
  </section>
  <section class="ViewDashboard__suggestions">
    SUGG
  </section>
  <section class="ViewDashboard__devices">
    DEV
  </section>

</div>
</template>


<script>
import SectionTitle from './SectionTitle.vue';
import QuickTune from './QuickTune.vue';
import Summary from './Summary.vue';
import Suggestions from './Suggestions.vue';
/**
 * @typedef {import('@/types').Api.Dashboard} DashboardData
 * @typedef {import('@/store/user').DashboardResult} DashboardResult
 * @typedef {import('@/types').Api.Result} SummaryData
 * @typedef {import('@/types').ApiResponse<SummaryData>} SummaryResult
 */
export default {
  name: 'ViewDashboard',
  components: {
    SectionTitle,
    Suggestions,
    Summary,
    QuickTune,
  },
  data() {
    return {
      /**@type {SummaryData} */
      summary: null,
      /**@type {DashboardData} */
      dashboard: null,
    };
  },
  inject: {
    $client: {
      from: 'client',
    }
  },
  async mounted() {
    await this.fetchDashboard();
    // await this.fetchSummary();
    // await this.getMedCard();
    // this.fetchData();
  },
  methods: {
    async getMedCard() {
      const result = await this.$client.get('/med_card/')
        .then(res => res.status !== 200 ? (error = `Error (${res.status})`, null) : res.data)
        .catch(err => (error = err.response?.data?.detail || err.toString(), null));
      console.log('MC', result);
    },
    async putMedCard() {
      const data = {
        weight: 100, // min value (kg): 20 man value (kg): 600
        hip: 20, // min value (cm): 20 max value (cm): 150
        waist: 20, // min value (cm): 20 max value (cm): 260
        wrist: 10, // min value (cm): 10 max value (cm): 40
        neck: 20, // min value (cm): 20 max value (cm): 120
        heart_rate_alone: 40, // min value: 27 max value: 220
        daily_activity_level: 1.55,
        blood_pressure_sys: 80, // min value: 40 max value: 230
        blood_pressure_dia: 120, // min value: 80 max value: 320
        cholesterol: 4.5, // min value: 0 max value: 9.0
        glucose: 7, // min value: 0 max value: 14.0
      };
      const result = await this.$client.put('/med_card/', data)
        .then(res => res.status !== 200 ? (error = `Error (${res.status})`, null) : res.data)
        .catch(err => (error = err.response?.data?.detail || err.toString(), null));
      console.log('MC', result);
    },

    async fetchData() {
      const [cr, de, re] = await Promise.all([
        await this.$client.get('/cardio_recomendations/'),
        await this.$client.get('/disease/'),
        await this.$client.get('/result/'),
      ])
      console.log('CR', cr.data);
      console.log('DE', de.data);
      console.log('RS', re.data);
    },

    async fetchDashboard() {
      /**@type {DashboardResult}*/
      const {error, data} = await this.$store.dispatch('user/dashboard');
      this.dashboard = data;
      console.log('dashboard', error || data);
    },
  },
}
</script>

<style lang="scss">
.ViewDashboard {
  display: grid;
  grid-template-columns: 1fr minmax(33%, 685px);
  gap: 1px;
  // &__summary {}
  // &__slider {}
  // &__quicktune {}
  &__suggestions {
    grid-area: auto / auto / span 2 / span 1;
  }
  // &__devices {}

  & > section {
    outline: 1px solid gray;
    outline-offset: -0px;
  }
}
</style>
