<template>
  <ChangePassword
    v-if="isChangePassword"
    @goToCodeVerificator="goToCodeVerificator()"
    @goToLogin="goToLogin()"
  />
  <CodeVerificator
    v-else-if="isForggotenPassSubmit"
    @resendCode="resendCode()"
    @codeSubmit="submitChangePassword($event)"
    @changeEmail="changeEmail()"
  />
  <template v-else>
    <div class="back" @click="$emit('back')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.7428 38.107L5.04572 25.41C4.26657 24.6308 4.26657 23.3676 5.04572 22.5884L17.7428 9.89141C18.5219 9.11225 19.7852 9.11225 20.5643 9.89141C21.3435 10.6706 21.3435 11.9338 20.5643 12.713L11.2732 22.0041L42.3691 22.0041L42.3691 25.9944L11.2732 25.9944L20.5643 35.2855C21.3435 36.0646 21.3435 37.3279 20.5643 38.107C19.7852 38.8862 18.5219 38.8862 17.7428 38.107Z"
          fill="CurrentColor"
        />
      </svg>
    </div>
    <span class="reset__header">Reset Password</span>
    <br />
    <span
      >Enter your email or phone number and we will send you a verification code
    </span>
    <div class="reset__input">
      <InputText placeholder="Email Or Phone" v-model="username" />
    </div>
    <div class="reset__submit">
      <button class="button reset__submit--button" @click.prevent="resetPassword()">
        Continue
      </button>
    </div>
  </template>
</template>
<script>
import CodeVerificator from "@/components/Auth/AuthForms/codeVerificator.vue";
import ChangePassword from "./changePassword.vue";
import InputText from "@/components/input-text/InputText.vue";

import { Auth } from "aws-amplify";

export default {
  components: {
    CodeVerificator,
    ChangePassword,
    InputText,
  },
  data() {
    return {
      username: "",
      isForggotenPassSubmit: false,
      isChangePassword: false,
    };
  },
  mounted () {
    const authComp = JSON.parse(localStorage.getItem("authComp"))
    this.username = authComp?.username || "",
    this.isForggotenPassSubmit = !!authComp.username,
    this.isChangePassword = !!authComp.code
  },
  methods: {
    async resetPassword() {
      try {
        await Auth.forgotPassword(this.username);
        localStorage.setItem("authComp", JSON.stringify({
          type: "login",
          isForggotenPass: true,
          username: this.username,
        }))
        this.isForggotenPassSubmit = true;
      } catch (e) {
        console.error(e);
      }
    },

    goToLogin() {
      this.isForggotenPassSubmit = false;
      this.isChangePassword = false;
      this.$emit("back");
    },
    goToCodeVerificator() {
      localStorage.setItem("authComp", JSON.stringify({
        type: "login",
        isForggotenPass: true,
        username: JSON.parse(localStorage.getItem("authComp"))?.username,
        code: "",
      }))
      this.isChangePassword = false;
    },

    async changeEmail() {
      localStorage.setItem("authComp", JSON.stringify({
        type: "login",
        isForggotenPass: true,
      }))
      this.isForggotenPassSubmit = false;
    },

    async submitChangePassword(code) {
      localStorage.setItem("authComp", JSON.stringify({
        type: "login",
        isForggotenPass: true,
        username: JSON.parse(localStorage.getItem("authComp"))?.username,
        code: code,
      }))
      this.isChangePassword = true;
    },
    resendCode() {
      try {
        Auth.forgotPassword(JSON.parse(localStorage.getItem("authComp"))?.username);
      } catch (e) {}
    },
  },
};
</script>


<style scoped lang="scss">
.back {
  display: flex;
  align-items: center;
  gap: 9px;
  color: var(--masstrich-blue);
}

.reset {
  &__header {
    font-family: DM Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--masstrich-blue);
  }

  &__input {
    padding-top: 72px;
    display: flex;
    gap: 48px;
    flex-direction: column;

    &--input {
      width: 100%;
      display: flex;
      padding: 8px 18px;
      align-items: center;
      border: none;
      border-radius: 6px;
      background-color: var(--water);
      gap: 10px;
    }

    input {
      &::placeholder {
        color: darken($water, 10%);
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: darken($water, 10%);
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  &__submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &--button {
      display: flex;
      width: 136px;
      height: 47px;
      padding: 8px 81px;
      margin-top: 108px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: var(--white);
      border-radius: 140px;
      border-width: 0px;
      cursor: pointer;
      background: var(--accent);
      box-shadow: 6px 6px 36px 0px rgb(0 0 0 / 65%);
    }
  }
}
</style>
