<template>
    <footer class="footer" v-show="!$store.state.mainFrame || isShop">
      <div class="flex-wrapper">
        <div class="footer__left-column">
          <div class="footer__info">
            <img class="logo" src="../assets/logo/Logo512x256.svg" />
            <span class="footer__subtitle">InTime BioTech LLC.</span>
            <p>Dba. Longevity InTime<br />
            300 Delaware Avenue, Suite 210-A, <br />
            Wilmington, Delaware, 19801, USA</p>
          </div>

          <div class="footer__contact">
            <span class="footer__subtitle">Contact us</span>
            <!-- <a href="https://intime.digital/en/web/contact/" class="footer__submit">Send a message</a> -->
            <a href="mailto:contact@longevityintime.org" class="footer__email">
              <img src="../assets/img/svg/envelope.svg" />
              <span>contact@longevityintime.org</span>
            </a>
            <ul class="footer__social">
              <li><a href="https://twitter.com/TETERINOLEG">
                <img src="../assets/img/social-icons/twitter.svg" /></a>
              </li>
              <li><a href="https://www.facebook.com/share/2rSnbRv93hv36rR4/?mibextid=LQQJ4d">
                <img src="../assets/img/social-icons/facebook.svg" /></a>
              </li>
              <li><a href="https://www.linkedin.com/company/longevityintime/">
                <img src="../assets/img/social-icons/linked_in.svg" /></a>
              </li>
              <li><a href="https://www.instagram.com/teterinolegjr/">
                <img src="../assets/img/social-icons/instagram.svg" /></a>
              </li>
              <li><a href="https://t.me/LongevityInTime">
                <img src="../assets/img/social-icons/telegram.svg" /></a>
              </li>
            </ul>
          </div>
          <div class="footer__app">
            <a href="https://play.google.com/store/apps/details?id=com.intime.diseaseTrackerPro" class="app-button">
              <img src="../assets/img/svg/google-play.svg" />
            </a>
            <!-- <a href="#" class="app-button">
              <img src="../assets/img/svg/app-store.svg" />
            </a> -->
          </div>
        </div>

        <div class="footer__right-column">
          <div class="footer__nav">
            <!-- <div class="nav-column">
              <span class="footer__subtitle">Navigation</span>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="#">Reviews</a></li>
                <li><a href="#">Who we are</a></li>
                <li><a href="#">App</a></li>
                <li><a href="#">Mission</a></li>
                <li><a href="#">Partners</a></li>
                <li><a href="#">Petition</a></li>
                <li><a href="#">Books</a></li>
              </ul>
            </div> -->
            <div class="nav-column">
              <span class="footer__subtitle">Navigation</span>
              <ul>
                <!-- <li><a href="#">BMI Disease Tracker</a></li>
                <li><a href="#">AntiCoronaVirus</a></li>
                <li><a href="#">Longevity petition</a></li>
                <li><a href="#">Longevity Scientific Resort</a></li> -->
                <li><router-link to="/shop">Shop</router-link></li>
								<li><router-link to="/shop/longevity-coin">LongevityCoin (LONG)</router-link></li>
                <li><router-link to="/tos">Terms of Use</router-link></li>
                <li><router-link to="/policies/privacy-policy">Privacy Policy</router-link></li>
              </ul>
            </div>
          </div>
          <p class="footer__note grey">Powered by artificial intelligence, verified by scientists, generated by Big Data obtained from evidence based only clinical trials.</p>
        </div>

      </div>

      <span class="copyright">Copyright © 2018-2024, InTime BioTech  LLC.</span>
    </footer>
  </template>

  <script>
  export default {
    name: 'AppFooter',
    computed: {
      isShop() {
        return (
          this.$route.path.startsWith("/shop") ||
          this.$route.path.startsWith("/products") ||
          this.$route.path.startsWith("/policies")
        );
      },
    }
  }
  </script>


  <style lang="css">

  .footer {
    width: 100%;
    padding: 3.8vw 5.6vw 18vw 5.6vw;
    background-image: url('../assets/img/svg/waves.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
    background-color: #FAFCFF;
    background-size: contain;
    box-sizing: border-box;
  }
  .footer > * {
    box-sizing: border-box;
  }

  .footer .flex-wrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .footer__left-column {
    flex-basis: 48%;
  }

  .footer__right-column {
    flex-basis: 60%;
    padding-top: 115px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer__info, .footer__contact {
    display: flex;
    flex-direction: column;
  }

  .footer__info {
    margin-bottom: 25px;
  }
  .footer__info p {
    font-size: 24px;
    line-height: 36px;
  }
  .footer__info .footer__subtitle {
    margin-bottom: 0px!important;
    margin-top: 25px;
  }

  .footer__app {
    margin-top: 60px;
  }
  .footer__app .app-button {
    margin-right: 48px;
  }
  .footer__app .app-button:last-child {
    margin-right: 0px;
  }

  .footer__nav {
    display: flex;
  }
  .footer__nav .nav-column {
    margin-right: 30%;
  }
  .footer__nav .nav-column:last-child {
    margin-right: 0;
  }

  .nav-column ul {
    padding: 0;
    list-style: none;
  }
  .nav-column ul li {
    margin-bottom: 12px;
  }
  .nav-column ul li a {
    font-size: 24px;
    line-height: 31px;
    text-decoration: none;
    color: #21355C;
    display: flex;
    align-items: center;
  }
  .nav-column ul li a:before {
    content: '';
    width: 0px;
    height: 3px;
    background-color: #3683FC;
    transition: all .5s ease-out;
  }
  .nav-column ul li a:hover {
    color: #3683FC;
  }
  .nav-column ul li a:hover:before {
    width: 18px;
    margin-right: 10px;
  }

  .footer .logo {
    width: 180px;
  }

  .footer__subtitle {
    font-size: 36px;
    line-height: 70px;
    font-style: normal;
    font-weight: 400;
    color: #0C1E3C;
    margin-bottom: 25px;
  }

  p {
    font-size: 21px;
    line-height: 27px;
    color: #21355C;
    margin: 0;
  }

  .footer__submit {
    width: 323px;
    height: 48px;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: #3683FC;
    background-color: transparent;
    border: 2px solid #3683FC;
    border-radius: 140px;
    position: relative;
    text-decoration: none;
  }
  .footer__submit:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../assets/img/svg/link-out.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
    position: absolute;
    right: 46px;
  }

  .footer__social {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
  }
  .footer__social li {
    margin-right: 35px;
  }
  .footer__social li:last-child {
    margin-right: 0;
  }

  .footer__social li img {
    width: 36px;
    height: 36px;
  }

  .footer__email {
    display: flex;
    margin: 0 0 26px 0;
    text-decoration: none;
  }
  .footer__email img {
    margin-right: 21px;
  }
  .footer__email span {
    font-size: 24px;
    color: #0C1E3C;
  }

  .footer__note {
    color: #55606A!important;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 36px;
  }

  .copyright {
    position: absolute;
    bottom: 36px;
    left: calc(50% - 204px);
    font-size: 24px;
    line-height: 31px;
    color: #FAFCFF;
    text-align: center;
  }


  @media only screen and (max-width: 1024px) {
    .footer {
      padding: 6.3vw 4.7vw 33vw 4.7vw;
      background-size: auto;
    }

    .footer .flex-wrapper {
      flex-direction: column;
    }

    .footer__left-column {
      flex-basis: 100%;
      margin: 0;
      display: flex;
      flex-flow: wrap;
    }

    .footer__right-column {
      padding: 0px;
    }

    .footer__nav {
      margin: 75px 0;
    }

    .footer__info {
      margin-right: 20%;
      margin-bottom: 0px;
    }

    .footer__app {
      margin-top: 45px;
    }

    .footer__note {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 414px) {
    .footer {
      padding: 12vw 8.8vw 33vw 8.8vw;
      background-size: 100% 10%;
    }

    .footer__info, .footer__contact {
      width: 100%;
    }

    .footer__info {
      margin-right: 0;
      flex-flow: wrap;
      align-items: center;
      margin-bottom: 10px;
    }

    .footer .logo {
      width: 72px;
    }

    .footer__info .footer__subtitle {
      font-size: 20px;
      line-height: 26px;
      margin-left: 24px;
      margin-top: 0px;
    }

    .footer__info p {
      margin-top: 18px;
      font-size: 16px;
      line-height: 20px;
    }

    .footer__subtitle {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 18px;
    }

    .footer__email span {
      font-size: 16px;
      line-height: 20px;
    }

    .footer__email img {
      width: 20px;
    }

    .footer__social {
      justify-content: space-between;
    }

    .footer__social li img {
      width: 24px;
      height: 24px;
    }

    .footer__submit {
      max-width: 300px;
      font-size: 16px;
      height: 36px;
    }

    .footer__app {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .footer__app .app-button {
      width: 47%;
      max-width: 162px;
      margin: 0;
    }

    .footer__app .app-button img {
      width: 100%;
    }

    .footer__right-column {
      padding-top: 45px;
    }

    .footer__nav {
      flex-direction: column;
      margin: 0px;
    }

    .footer__nav .nav-column {
      margin-bottom: 20px;
    }

    .footer__nav .nav-column:first-child {
      margin-right: 0px;
    }

    .footer__nav .nav-column ul li a {
      font-size: 16px;
      line-height: 20px;
    }

    .footer__note {
      max-width: 100%;
      margin: 13px 0;
    }

    p, .copyright {
      font-size: 16px;
      line-height: 20px;
    }

    .copyright {
      left: auto;
    }
  }


  </style>
