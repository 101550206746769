<template>
<div class="UserLoginCard">

  <h1>Welcome</h1>

  <div class="UserLoginCard__avatar">
    <icon-svg v-if="!profile?.image" symbol="avatar"/>
    <img v-else width="144" height="144" :src="profile?.image" alt="."/>
  </div>

  <p>{{$store.getters.username}}</p>

  <button class="button" @click.prevent="logout()">Logout</button>

</div>
</template>

<script>
import {mapState} from 'vuex';
import {UserState, UserProfile} from '@/store/user/types';
import { Auth } from "aws-amplify";
export default {
  name: 'AuthUser',
  computed: {
    ...mapState('user', {
      /** @type {(state: UserState) => UserProfile} user profile */
      profile: state => state.profile,
    }),
  },
  methods: {
    async logout () {
       await Auth.signOut()
       this.$store.dispatch('user/logout')
    }
  }
}
</script>

<style lang="scss">
.UserLoginCard {
  text-align: center;
  &__avatar {
    margin: 0 auto;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img,
    & > .icon {
      box-sizing: border-box;
      flex: 0 0 auto;
      height: 100%;
      width: 100%;
    }
    & > img {
      object-fit: scale-down;
    }
    & > .icon {
      background: $accent;
      color: $accent-lighten;
      padding: 8%;
    }
  }

  & > button {
    cursor: pointer;
    color: $accent;
    font: inherit;
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-bottom: 1px dashed currentColor;
    line-height: .95;
  }
}
</style>
