/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	userPoolClientId: "4ketq8d68m0q83mnq1o0j4u745",
	region: "us-east-1",
	userPoolId: "us-east-1_jTrm8n2fY",
	userPoolWebClientId: "4ketq8d68m0q83mnq1o0j4u745",
	identityPoolId: "us-east-1:3780a4c0-2aab-4b19-b56f-91943b068616",
	aws_project_region: "us-east-1",
	aws_cognito_region: "us-east-1",
	aws_user_pools_id: "us-east-1_jTrm8n2fY",
	aws_user_pools_web_client_id: "4ketq8d68m0q83mnq1o0j4u745",
	aws_project_region: "us-east-1",
	aws_cognito_username_attributes: [],
	aws_cognito_social_providers: ["Google"],
	identityPoolRegion: "us-east-1",

	aws_cognito_signup_attributes: ["UPDATED_AT"],
	aws_cognito_mfa_configuration: "OPTIONAL",
	aws_cognito_mfa_types: ["SMS", "TOTP"],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS", "REQUIRES_SYMBOLS"],
	},
	aws_cognito_verification_mechanisms: ["EMAIL", "PHONE_NUMBER"],
	Auth: {
		identityPoolRegion: "us-east-1",

		Cognito: {
			userPoolClientId: "4ketq8d68m0q83mnq1o0j4u745",
			region: "us-east-1",
			userPoolId: "us-east-1_jTrm8n2fY",
			userPoolWebClientId: "4ketq8d68m0q83mnq1o0j4u745",
			identityPoolId: "us-east-1:3780a4c0-2aab-4b19-b56f-91943b068616",
			aws_project_region: "us-east-1",
			aws_cognito_region: "us-east-1",
			identityPoolRegion: "us-east-1",
			aws_user_pools_id: "us-east-1_jTrm8n2fY",
			aws_user_pools_web_client_id: "4ketq8d68m0q83mnq1o0j4u745",
			loginWith: {
				oauth: {
					domain: "auth.longevityintime.com",
					scopes: ["email", "openid"],
					redirectSignIn: [
						"https://auth.longevityintime.com/login",
						// "http://localhost:8080/login",
						"943625008306-6ief5h48ehtvl5eibiso4n7ekn51p5dt.apps.googleusercontent.com",
					],
					redirectSignOut: ["https://longevityintime.com"],
					urlOpener: (url) => {
						const left = (window.screen.width - 600) / 2;
						const top = (window.screen.height - 549) / 2;
						window.windowProxy = window.open(
							url,
							"center window",
							`resizable = yes, width=600,height=549,top=${top},left=${left}`
						);
						return Promise.resolve(window.windowProxy);
					},

					responseType: "token",
				},
			},
		},
	},
	oauth: {
		userPoolClientId: "4ketq8d68m0q83mnq1o0j4u745",
		region: "us-east-1",
		userPoolId: "us-east-1_jTrm8n2fY",
		userPoolWebClientId: "4ketq8d68m0q83mnq1o0j4u745",
		identityPoolId: "us-east-1:3780a4c0-2aab-4b19-b56f-91943b068616",
		aws_project_region: "us-east-1",
		aws_cognito_region: "us-east-1",
		aws_user_pools_id: "us-east-1_jTrm8n2fY",
		aws_user_pools_web_client_id: "4ketq8d68m0q83mnq1o0j4u745",

		identityPoolRegion: "us-east-1",

		domain: "auth.longevityintime.com",
		scopes: ["email", "openid"],
		redirectSignIn: [
			// "https://auth.longevityintime.com/login",
			"https://longevityintime.com/",
			// "http://localhost:8080/",
			// "943625008306-6ief5h48ehtvl5eibiso4n7ekn51p5dt.apps.googleusercontent.com"
		],
		redirectSignOut: ["https://longevityintime.com"],
		responseType: "token",
		urlOpener: (url) => {
			const left = (window.screen.width - 600) / 2;
			const top = (window.screen.height - 549) / 2;
			window.windowProxy = window.open(
				url,
				"center window",
				`resizable = yes, width=600,height=549,top=${top},left=${left}`
			);
			return Promise.resolve(window.windowProxy);
		},
	},
};

export default awsmobile;
