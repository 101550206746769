<style lang="scss" src="./styles.scss"/>
<script>
import Header from '@/components/Header.vue'

export default ({
    name: 'Mis',
    components: {
        Header
    }
})
</script>

<template>
    <Header />
    <main>
        <h1>Company Mission</h1>
            <br><br>
                <h2 >From the speech to students of the Faculty of <br>Economics of Moscow State University, Department of <br>Biotechnology March 10, 2020</h2>
                <br>
                <blockquote >
                    <p>Well-known Danish physicist <cite>Niels Bohr</cite> once said: "If the idea does not seem crazy, there will be no use in it."</p>
                </blockquote>
                
                <p>Well-known Danish physicist Niels Bohr once said: "If the idea does not seem crazy, there will be no use in it."</p>
                <p>Today we’ll talk about how Artificial Intelligence and Machine Learning can not only change the world, everybody talks about it,<br> but how they can solve the problem of longevity.</p>
                <p>First of all, I would like to ask five questions, and at the end of my short speech I would like each of you to think and give yourself answers to these questions.</p>
                <p>What is time? What drives us in life, drives our career, success, love?</p>
                <p>What is death? What actually killed Steve Jobs at age 56, Bill Gates partner - Microsoft co-founder Paul Allen at 65, Bill Gates mother at 64?</p>
                <p>How would our world change if people lived not 70-80 years, but at least 120 years, and if they lived 200-300 years?</p>
                <p>You must admit that only the thought of the possibility of living for more than 100 - 120 years looks as crazy as the world today without the Internet. Most of you were born when the Internet was just beginning to conquer people, and just a little less than 30 years ago there was no Internet at all, it was not in the form it is now even 20 years ago. And you can imagine a camcorder the size of a laptop, and a phone the size of a tablet. Can you imagine how such a huge video camera could fit in such a huge mobile phone? Just 15 years ago, that was exactly how it was - there was not a single smartphone, even with half of today's functions. What it says, it says that "the impossible is possible."</p>
                <p>Niels Bohr also said "nothing exists until it is measured." Death is measured. Already today, many scientists believe that death is a disease. We at Longevity Intime Corporation have gone even further, we believe that death is another addiction. A man is woven from habits, different habits. Habits to go to the doctor once a year and habits not to go to the doctor at all, the habit of dying ahead of time.</p>
                <p>How Artificial Intelligence and Machine Learning can help us fight this addiction? How to make us live longer?</p>
                <p>The answer is simple and oddly enough, rather boring - early diagnosis of diseases. You can still use the synonym “prevention of non-onset diseases”, like vaccination or even more tired “medical examination” (annual, or better often).</p>
                <p>Here it’s worth a little break and ask a few more questions - “we, today's audience, are young, it’s all too early for us, and indeed, what kind of long life are you talking about, we are more interested in how, where and with whom to hang out, something to drink, smoke, what the hell of active old age and longevity". And, probably, many of you are telling yourself when terrible thoughts come: “Well, this will not happen to me, really with anyone else, with anyone else, but not with me”.</p>
                <p>And here I would argue with you. Steve Jobs fell ill in his youth, no one knows the exact date, but he was diagnosed with the first diagnosis at 27, Paul Allen was diagnosed at 30, which means that they all became ill at the age of 20-25. Why are they sick? Because, despite their advanced mind, knowledge and by that time money - they are people like us, they are people made of habits. Their brain, like our brain, cannot recognize its limb, they ignored the recommendations to go to doctors , drove away any negative thoughts from themselves and this is what it led to, the three richest families on the planet lost their members 20-30 years earlier than their physical term, and if you look at the statistics of longevity among people with the highest concentration of wealth, then as a rule these people live to quality 100 years, i.e. Steve Jobs, Paul Allen and Bill Gate's mother died 35-45 years earlier than their time.</p>
                <p>The man is unique and not only with fingerprints and the iridescent cornea. Human life is considered the most valuable resource by the constitutions of most countries, because it is finite. But all of our time and our lives spit, and we ourselves. How to determine the state’s development - by the presence of lines, the larger the line somewhere, the less developed the state and vice versa. Queue is an example of how our time is not respected. We repair our gadgets, buy glass from screen scratches, shockproof cases, clean laptops, and hand over cars to the service. And with ourselves, why don't we do this? Because we don’t have such a habit and we don’t have “time” and our brain, despite being the most complex organ on Earth (and some scientists, in particular, Tatyana Chernigovskaya, consider it the most mysterious “thing” that the brain is not just a neural network, but a network of networks, a network of networks of networks), it is not able to realize its limb and calmly give a command to go and be examined and give this command regularly.</p>
                <p>And when we come to the doctors, what awaits us? Therapists, laboratory assistants, do not advise doing extensive analyzes, saying: “Why would you spend that much money, these additional studies cost substantial money, and the likelihood that you have “something wrong” there is practically zero”.</p>
                <p>Logically, you will listen, and you won’t make the checkup necessary for your unique organism. This is exactly what happened with Jobs, who was diagnosed with cancer, which was 13th on the list of the most common oncological diseases. Are you ready to entrust your life to a therapist in a clinic or, even worse, to a laboratory assistant of some reputable laboratory for taking tests?</p>
                <p>In the United States and many developed countries, about 30% of all deaths are due to medical errors or overdose of drugs due to incorrect interpretation of prescriptions, instructions. There are no such statistics in the Russian Federation, but I think the numbers are similar.</p>
                <p>What to trust? Today, no matter how doubtful Google and Facebook, Apple and Samsung, Sberbank and Tinkoff would be, we use the services and goods of these companies. On phones, some of us store our intimate photos, on social networks, and again through the phones we enter the data of our bank cards - we certainly trust these “black mirrors” that steal our hours of active life from us, like drug addicts who can’t tear themselves away from other doses. So why don't we then trust our health to digital technologies, telephones, algorithms, cold-blooded artificial intelligence. Of course, under the control of a person, experts, yourself in the end.</p>
                <p>Analyzing all this information, reasoning, we at Longevity Intime Corporation came to the conclusion that if you cannot defeat the habit of not going to the doctor, defeat your own indifference to yourself, and avoid medical errors and fatal advice, self-medication, then you need to create automated health-saving technology that can measure, analyze and propose solutions.</p>
                <p>Two years we needed to compare our research with the opinions of leading scientists in the field of longevity. It seems that two years is not much, but relative to the existence of smartphones, it is less than 10%, and almost as much as mobile phones with three video cameras were developed. Time has become even more relative as progress has progressed.</p>
                <p>Thus, we began the creation of 4P medicine technology - Predictive, Preventive, Participatory, Personalized, the essence of which is to take a person’s biodata in real time using wearable electronics without any additional inconvenience. You say that today they are already taking data, yes, you will be right. But just how much data does Google Fit and Apple Health, Garmin, FitBit and others take together? Over the entire period of the existence of online technologies, we were able to achieve removal of only about 17 parameters, but we plan to shoot 50. And here it is important to remember that many of us dabbled in various fitness bracelets, gadgets to measure data and quit using them, and all because static information “how much time has passed, what has been eaten, what was the heart rate” - this data tells us or insignificant information. Since you are not specialists, you are not doctors, you will not be able to decrypt it correctly, even they will not say anything to many specialists, since depending on the combination of this data with your other data (which must be taken by other devices), the indicators you personally receive may mean one thing, and for another human – something else. The basic principle of 4P medicine - from general to particular. We said that we are unique, this is the key. Therefore, we are creating an algorithm that turns static data into dynamic data. In addition to the frequency of measurements (some constantly, some once a day), we present our client with data in a convenient, readable, understandable and applied format, where it is written what this data means for him personally and what needs to be done with them.</p>
                <p>Biodata from the subject of wearable electronics is certainly great, but this is not enough. What else is needed? And how to still circumvent human laziness and the habit of saying to yourself "there is no time for analysis." We analyzed the market of portable containers - Rapid Distant Tests devices - devices for remote express analyzes, one of the most famous cases was Teranos (Elizabeth Holmes), the company declared that it can take a drop of blood and analyze it in the blood sampling device itself. Everything would be fine, but the analysis in it is the subject of future technologies. Teranos claimed that he would do it today in the “kitchen”, which turned out to be a lie and the company closed itself. Their new competitor, Atomo Diagnostics, funded by the Australian government, takes blood from the device and analyzes it in the laboratory.</p>
                <p>We have gone even further; we are upgrading the device. If we take the blood (so far from the finger and believe that we have enough parameters from such blood), store it there for up to 8 hours at a temperature of 2 to 6 degrees, then why not take some other biomaterials? We decided to take urine, skin, hair, a piece of nail and now we think how to take feces with a dry strip with a membrane. We plan to send all these biomaterials to the laboratory.</p>
                <p>Data analysis, according to more than 350 parameters, is matched to 50 biodata from the clock and analyzed by our artificial intelligence server, which provides fairly accurate data about your current health and life trajectories for the future in the most user-friendly form in a mobile application. The motivation of a person is to live longer, our client is the one who chose this motivation, and he does not need to be forced to do it all and will not be embarrassed to take blood and urine. If necessary, we will send him a nurse.</p>
                <p>As a result, we automated the process of taking biodata in real time, analyzing it, obtaining information about the state of health, drew a trajectory of the future based on the present, and offer recommendations on what to follow so that the trajectory of life is as long as possible.</p>
                <p>This device, or at least an approach to researching yourself for the purpose of early diagnosis, could focus on “hot spots” in your body, which could be the subject of additional laboratory research and extend the life of millions of people.</p>
                <p>We are not against doctors or laboratory tests, but we are for “another opinion”, which is made by a machine and checked by a person.</p>
                <p>Our application will have a virtual office of the attending physician, to whom the agreed client will open access, and the doctor will be able to reconcile his conclusions in real time with the changing state of his client. The goal is to catch the butterfly effect when it is not too late to change something that prolongs an active life.</p>
                <p>Our customers in the B2C, B2C, B2G segments are millionaires, after entering the mass market, incl. Big Pharma, insurance, sports teams, medical institutes, private doctors, famous people.</p>
                <p>Our goal is to extend the active part of a person’s life by at least 20 years, so that in the most healthy body, as much as possible, we can all meet the 2040s, when, according to futurologists, scientists, visionaries, there will come a point of singularity (the moment when our mind is not not able to understand the progress that is taking place, development scenarios have been shown in dozens of Hollywood science fiction films, it makes no sense to list them). It is believed that in 20-25 years, science and technology, among other things, will be able to “break” the millennium problem and make life if not eternal, then so long qualitatively that it can be considered eternal.</p>
                <p>Everything described above is only Phase One of our product, after 2030 we will start in Phase Two - to the point that it can extend life beyond 120 years. Scientists believe that physically a body can live up to 120 years only, but do we need such a body? Who are we in the end, the body, consciousness, brain or soul? So far, no one has answers to this, but today at least 10 corporations and institutes in the world are wondering if it is possible to transfer consciousness to a flash drive / computer and then to another body? Fantastic? Today - maybe tomorrow? We at Longevity Intime also asked this question today.</p>
                <p>All the skeptics have only one answer - we are talking about giving a person a choice, today we all have no choice, or we will live up to 70-80-90 years in a relatively healthy mind, like old people with an appropriate quality of life, or on a gurney chair, in the form vegetable. That is why many do not want to live long. But if you live 120+ with a quality of life of a maximum of 50-60-years old, I believe that most will change their philosophical considerations in this regard. A study was conducted among super longlivers. Here is a recipe for their 100+ years old: mental (incl. Memory training) and physical activity; strong social ties (incl. Recognition of the environment); moderate nutrition; optimism; favorite job (incl. high level of creativity); measured way of life; regular medical care; mild climate (incl. low light pollution).</p>
                <p>The experts (and again the quote from Niels Bohr) of our company are those who “throughout their lives, making mistakes in some narrow field, have gained the proper experience”. Among them are Vadim Gladyshev, Alexey Moskalev, Andrey Lisitsa. We have a unique case: 25 developers are working on the first versions of the product on the terms of options, without traditional salaries, because they believe in the product being created and want, among other things, to live longer themselves, and they want to try to give the extra years of life to their near and dear ones.</p>
                <p>It’s probably impossible to calculate how many people in the world are professionally engaged in the problems of longevity, but according to my rough estimates, 100 thousand people, well, about 1 million. And what if the remaining 7.999 million people at least 1 minute a day would deal with issues of longevity, although would be your own. The impossible is possible?</p>
                <p>It is possible and necessary to work. But what is the point of working in exchange for money if this work takes away health and therefore life? Not the fact that for the money you earn you can buy yourself years of active life. Founders of Apple and Microsoft failed. Therefore, I think the correct formula is to change your time (i.e. life) for money, only working in the field of products and services, the dividends of which will be additional quality years of life. There will be many other temptations, try to adhere to the specified formula. We invite you to join our like minded team.</p>
                
                <p>So what is Time?</p>
                <br>
                <p>Thanks.</p>
                <p><em>Oleg Teterin - expressed his personal opinion</em></p>
            
    </main>
</template>
