<template>
<div class="ViewUserProfile">

  <div class="ViewUserProfile__nav">
    <ul>
      <li>Profile</li>
      <li>Diseases</li>
      <li>Billing</li>
      <li>Security</li>
      <li>Connections</li>
    </ul>
  </div>

  <div class="ViewUserProfile__sections" v-if="$store.getters.username">
    <i>{{$store.getters.username}}</i>
    <b>{{profileChanged}} & {{dashboardChanged}}</b>

    <fieldset>

      <strong>
        <UserAvatar/>

      </strong>

      <div>

        <FieldsetProfile v-model="valuesProfile"/>
      </div>
    </fieldset>

    <fieldset>
      <strong>Diseases</strong>
      <div>
        0
      </div>
    </fieldset>

    <fieldset>
      <strong>Billing</strong>
      <div>
        0
      </div>
    </fieldset>

    <fieldset>
      <strong>Security</strong>
      <div>
        0
      </div>
    </fieldset>

    <fieldset>
      <strong>Connections</strong>
      <div>
        0
      </div>
    </fieldset>

  </div>

  <div class="ViewUserProfile__buttons">
    <p>
      <button class="button" @click.prevent="submit">Submit</button>
    </p>
    <!-- <p>
      <button @click.prevent="$store.dispatch('user/logout')">Logout</button>
    </p> -->
  </div>


</div>
</template>

<script>
import {UserProfile} from '@/store/user/types';
import {AxiosInstance, AxiosRequestConfig} from 'axios';
import UserAvatar from '@/components/UserAvatar.vue';
import FieldsetProfile from './profile.vue';
/** @typedef {import('./profile.vue').FieldsetValues} ProfileValues
 * @typedef {import('@/types').Api.Dashboard} DashboardData
 * @typedef {import('@/store/user').DashboardResult} DashboardResult
 */

export default {
  name: 'ViewUserProfile',
  components: {
    UserAvatar,
    FieldsetProfile,
  },
  // computed: {
  //   ...mapState('user', {
  //     /** @type {(state: UserState) => string} */
  //     image: state => state.profile?.image || '',
  //   }),
  // },
  data() {
    return {
      /**@type {DashboardData}*/
      dashboard: null,
      /** Initial values ​​(for checking for change)
       * @type {ProfileValues}
       */
      currentProfile: null,
      /**@type {ProfileValues}*/
      valuesProfile: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        birth_date: '',
        gender: '',
        country: '',
      },
    };
  },
  async mounted() {
    /**@type {DashboardResult}*/
    const {error, data} = await this.$store.dispatch('user/dashboard');
    this.dashboard = data;
    this.currentProfile = {...this.resetInputValues()};

    // await this.putResult();
    // await this.getMedCard();
  },
  computed: {
    dashboardChanged() {
      if (!this.currentProfile) return false;
      // const dashboard = this.dashboard || {};
      const keys = ['birth_date', 'gender', 'country'];
      const changed = keys.filter(key => String(this.currentProfile[key]) !== this.valuesProfile[key]);
      console.log('changed D', changed);
      return !!changed.length;
    },
    profileChanged() {
      if (!this.currentProfile) return false;
      // const profile = this.$store.state.user.profile || {};
      // console.log(String(profile['phone']), this.valuesProfile['phone']);
      const keys = ['email', 'phone'];
      const changed = keys.filter(key => String(this.currentProfile[key]) !== this.valuesProfile[key]);
      console.log('changed P', changed);
      return !!changed.length;
    },
  },
  methods: {
    resetInputValues() {
      /**@type {UserProfile}*/
      const profile = {...this.$store.state.user.profile};

      /**@type {DashboardData}*/
      const dashboard = {...this.dashboard};

      console.log('profile', profile);
      console.log('dasbord', dashboard);

      // let birth_date = dashboard.birth_date || '';
      // if (birth_date) {
      //   const [dd, mm, yyyy] = birth_date.split('.');
      //   birth_date = [yyyy, mm, dd].join('-');
      // }

      /**@type {ProfileValues}*/
      const valuesProfile = {
        email: profile.email || '',
        phone: profile.phone || '',
        gender: String(dashboard.gender || ''),
        country: String(dashboard.country || ''),
        birth_date: dashboard.birth_date || '',
      };
      return this.valuesProfile = valuesProfile;
    },
    async submit() {
      const success = await Promise.all([
        this.submitProfile(),
        this.submitDashboard(),
      ]);
      // const error = success.find(ok => ok !== true);
      // console.log('RESET', error);
      this.currentProfile = {...this.resetInputValues()};
    },
    async submitProfile() {
      if (!this.profileChanged) return true;
      const payload = {
        email: this.valuesProfile.email,
      };
      const {error} = await this.$store.dispatch('user/profileUpdate', payload);
      return !error;
    },
    async submitDashboard() {
      if (!this.dashboardChanged) return true;
      const payload = {
        gender: this.valuesProfile.gender,
        birth_date: this.valuesProfile.birth_date,
        country: this.valuesProfile.country,
      };
      const {data, error} = await this.$store.dispatch('user/dashboardUpdate', payload);
      if (!error) this.dashboard = data;
      return !error;
    },

    async getMedCard() {
      const result = await this.$client.get('/med_card/')
        .then(res => res.status !== 200 ? (error = `Error (${res.status})`, null) : res.data)
        .catch(err => (error = err.response?.data?.detail || err.toString(), null));
      console.log('MC', result);
    },
    async putMedCard() {
      const data = {
        weight: 100, // min value (kg): 20 man value (kg): 600
        hip: 20, // min value (cm): 20 max value (cm): 150
        waist: 20, // min value (cm): 20 max value (cm): 260
        wrist: 10, // min value (cm): 10 max value (cm): 40
        neck: 20, // min value (cm): 20 max value (cm): 120
        heart_rate_alone: 40, // min value: 27 max value: 220
        daily_activity_level: 1.55,
        blood_pressure_sys: 80, // min value: 40 max value: 230
        blood_pressure_dia: 120, // min value: 80 max value: 320
        cholesterol: 4.5, // min value: 0 max value: 9.0
        glucose: 7, // min value: 0 max value: 14.0
      };
      const result = await client.put('/med_card/', data, {ba})
        .then(res => res.status !== 200 ? (error = `Error (${res.status})`, null) : res.data)
        .catch(err => (error = err.response?.data?.detail || err.toString(), null));
      console.log('MC', result);
    },
    async putResult() {
      const data = {
        base_metabolism: 1,
        bmi: 1,
        body_type: "sasa",
        calories_to_low_weight: 1,
        common_recomendations: [],
        common_risk_level: {},
        disease_risk: [],
        ideal_weight: 1,
        obesity_level: {},
        passport_age: 1,
        waist_to_hip_proportion: 1,
      };

      /**@type {AxiosRequestConfig}*/
      let error = '';
      const config = {baseURL: 'https://longevityintime.org/api/v1/'};
      const result = await this.$client.put('/set_result/', data, config)
        .then(res => res.status !== 200 ? (error = `Error (${res.status})`, null) : res.data)
        .catch(err => (error = err.response?.data?.detail || err.toString(), null));
      console.log('PR', error, result);
    },
  },
}
</script>

<style lang="scss">
.ViewUserProfile {
  display: grid;
  grid-template-columns: auto 1fr auto;
  &__nav {
    ul {@include list-reset}
    ul {
      position: sticky;
      top: 0;
      padding-top: 4rem;
    }
  }

  &__sections fieldset {
    min-height: 600px;
    display: flex;
    flex-flow: row nowrap;
    border: 0;
    padding: 0;
    outline: 1px solid whitesmoke;
    & > strong {
      flex: 0 0 auto;
      font-size: 3rem;
      font-weight: 400;
      width: 200px;
      text-align: right;
      overflow: visible;
      direction: rtl;
      white-space: nowrap;
      margin: 0 3rem 0 0;
      .UserAvatar {
        display: block;
        width: 145px;
        height: 145px;
      }
    }
    & > div {
      flex: 1 1 100%;
    }
  }
}
</style>
