<style lang="scss">
.DashboardSummary {
  $Hug: 47px;

  &__title {
    margin-bottom: 2rem;
  }

  &__time {
    display: block;
    margin: -2rem 0 .5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  &__risk {
    display: flex;
    white-space: nowrap;
    align-items: center;
    & > div {
      flex: 1 1 100%;
      margin: 2px 0 0 1rem;
      background: $accent-lighten;
      height: 10px;
      border-radius: 5px;
      & > b {
        display: block;
        background: $accent;
        border-radius: inherit;
        height: 100%;
      }
    }
  }

  &__cards {@include list-reset}
  &__cards {
    margin: 1.5rem 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 3rem;
    & > li {
      background: white;
    }
  }

  .SummaryCard {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: flex-start;

    background: white;
    color: var(--masstrich-blue);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 1.125rem 1.5rem;
    min-height: 144px;
    strong {
      flex: 1 1 100%;
      font-weight: 400;
      color: var(--indigo);
      margin: 0 0 auto;
    }
    span {
      font-size: 2.25rem;
      line-height: 1;
    }
    small {
      color: $yellow;
      color: $green;
      color: $red;
      display: inline-block;
      margin-left: auto;
      font-size: 100%;
      .icon {
        width: 14px;
        height: 14px;
        vertical-align: baseline;
      }
    }
  }
}
</style>

<template>
<div class="DashboardSummary">
  <section-title class="DashboardSummary__title"
    text="Summary"
    link="Risks"
    :route="{name: 'home'}"/>

  <time class="DashboardSummary__time"
    v-if="daysAgo" v-text="daysAgo"/>

  <div class="DashboardSummary__risk">
    <span>{{'Middle risk'}}</span>
    <div><b :style="{width: '50%'}"/></div>
  </div>

  <ul class="DashboardSummary__cards">
    <li v-for="(k, i) in 5">
      <div class="SummaryCard">
        <strong>BMI</strong>
        <span>28.8</span>
        <small>
          <icon-svg symbol="arrow-top-right"/>
          &plus;0.3
        </small>
      </div>
    </li>
  </ul>

</div>
</template>

<script>
/** @typedef {import('@/types').Api.Result} SummaryData */
import SectionTitle from './SectionTitle.vue';
export default {
  name: 'DashboardSummary',
  components: {SectionTitle},
  inject: {
    $client: {
      from: 'client',
    }
  },
  props: {
    /** @type {import('vue').PropType<DashboardData>} */
    // dashboard: {type: Object, required: true},
  },
  data() {
    return {
      summary: null,
      daysAgo: null,
    };
  },
  async mounted() {

    /**@type {import('@/types').ApiResponse<SummaryData>}*/
    const {error, data} = await this.$store.dispatch('user/summary');
    this.summary = error ? null : data;
    console.log('summary', error || data);

    this.fetchDaysAgo().then(days => this.daysAgo = days);

  },
  methods: {
    /**@return {Promise<string>} age string */
    async fetchDaysAgo() {
      const res = await this.$client.get('/getfirstresulttime/');
      if (!res?.data.timestamp) return null;
      const time = Date.now() - (res.data.timestamp * 1e3);
      const days = Math.floor(time / 3600e3 / 24);
      if (days <= 0) return 'Newer';
      if (days === 1) return 'Today';
      if (days === 2) return 'Yesterday';
      return `${days} days ago`;
    }
  },
}
</script>
