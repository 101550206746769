<style lang="scss" src="./styles.scss" />
<script>
	import Header from '@/components/Header.vue'

	export default ({
		name: 'Doc',
		components: {
			Header
		}
	})
</script>

<template>
	<Header />
		<h1>Doctors-reletions</h1>
		<br><br>
		<p>Longevity InTime Biotech is a telemedicine company that delivers TOP 20 Severe Diseases Online Detection &
			Prevention Program to self-insured employers, sportsmen, health plans & consumers.</p>
		<p>Longevity InTime Biotech is a 5-year prevention program designed to help patients to reduce their risk for
			developing TOP 20 disease, incl heart attack, stroke, diabetes, cancers.</p>
		<p>The program helps patients with pre-severe diseases risk to reduce their risk for developing major life
			shortening diseases by 74% based on researches of our scientific team.</p>
		<p>Longevity InTime Biotech has raised more than $2 million in capital from approximately 50 likeminded
			professionals who have invested in the company and serve on our every day basis & advisory board.</p>
		<p>We invite physicians, private doctors to invest in Longevity InTime Biotech. Our doctors & physicians
			normally invest $15K-$25K and can use an IRA/401K (for USA) to invest.</p>


</template>
