import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SoonView from "../views/SoonView.vue";
import ViewDashboard from "@/views/dashboard/index.vue";
import ViewUserProfile from "@/views/profile/index.vue";
import store from "@/store";
import Vac from "@/views/page/EN/Vaccancies.vue";
import Mis from "@/views/page/EN/Mission.vue";
import Inv from "@/views/page/EN/Investor_reletion.vue";
import Doc from "@/views/page/EN/Doctors_reletion.vue";
import Sup from "@/views/page/EN/Support_research.vue";

import login from "@/components/Auth/login.vue";

// import validateAuth from "@/middlewares/auth";
import { Auth } from "aws-amplify";

import { CognitoJwtVerifier } from "aws-jwt-verify";
import MiniApp from '@/components/MiniApp.vue';
import Leaflet from '@/components/Leaflet.vue';
import UserLeaflet from '@/components/UserLeaflet.vue';

//  {
//     path: "/about",
//     name: "about",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
//   },

/**
 * [] fix long import names
 * [] use name.vue on all
 *
 */
let enums = {
	// full -> email + pass + mfa
	// https://github.com/aws-amplify/amplify-js/issues/1582#top
	// may need custom mmfa endpoints
	// aws does not have backups, may need to save otp key and decode with provid
	auths: ["full", "password", "mfa-mail", "mfa-any", "backupcode-"],
};

const shopMenu = [
	{ url: "/", label: "Home", hash: "" },
	{ url: "/shop", label: "Shop", hash: "" },
];

// authed
const authedroutes = [
	{ path: "/dashboard/", alias: "/dashboard", name: "dashboard", component: ViewDashboard },
	{ path: "/profile/", name: "profile", component: ViewUserProfile },
	{
		path: "/ui",
		meta: {
			auth: { required: true, mode: enums.auths["full"] },
		},
		name: "ui",
		component: () => import("@/views/ui-kit/index.vue"),
	},
	{
		path: "/cart",
		alias:"/Checkout",
		meta: {
			auth: { required: true, mode: enums.auths["full"] },
		},
		name: "Checkout",
		component: () => import("@/views/shop/cart/index.vue"),
	},
	{ path: "/ui-kit", name: "ui-kit", component: () => import("../views/UIKitView.vue") },
];

for (let i = 0; i < authedroutes.length; i++) {
	if (!("auth" in authedroutes[i])) {
		authedroutes[i]["auth"] = {
			required: true,
			mode: enums.auths["full"],
		};
	}
}

const routes = [
	...authedroutes,

	// public
	{ path: "/", name: "lp", component: () => import("../views/LandingPage/LandingPageView.vue") },
	{ path: "/about", name: "about", component: () => import("../views/AboutView.vue") },
	{ path: "/vaccancies/", name: "vaccancies", component: Vac },
	{ path: "/support-research/", name: "support", component: Sup },
	{ path: "/Mission/", name: "Mission", component: Mis },
	{ path: "/Investor/", name: "Investor", component: Inv },
	{ path: "/Doctors/", name: "Doctors", component: Doc },
	{ path: "/Support/", name: "Support", component: Sup },
	{ path: "/login/", name: "login", component: login },

	{
		path: "/pages/ceo",
		name: "ceo",
		component: () => import("../views/page/EN/ceo/index.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/policies/privacy-policy",
		name: "privacy-policy",
		component: () => import("../views/page/EN/policies/privacy-policy/index.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/contact",
		name: "contact-information",
		component: () => import("../views/page/EN/policies/contact-information/index.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/policies/refund-policy",
		name: "refund-policy",
		component: () => import("../views/page/EN/policies/refund-policy/index.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/policies/terms-of-service",
		name: "terms-of-service",
		component: () => import("../views/page/EN/policies/terms-of-service/index.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/tos",
		name: "terms-of-service",
		component: () => import("../views/page/EN/policies/terms-of-service/index.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/shop",
		name: "shop",
		component: () => import("../views/shop/shop.vue"),
		meta: { menuHeader: shopMenu },
	},
	{
		path: "/progress",
		name: "progress",
		component: () => import("../views/page/EN/progress/index.vue"),
	},
	// {
	// 	path: '/cart',
	// 	name: 'cart',
	// 	component: () => import('../views/cart/index.vue'),
	// 	meta: { // 		menuHeader: shopMenu // 	}
	// },
	{
		path: "/shop/:handle",
		name: "product",
		component: () => import("../views/shop/products/_handle.vue"),
		props: true,
		meta: { menuHeader: shopMenu },
	},
	// {
	// 	path: "/product/:handle",
	// 	name: "product",
	// 	component: () => import("../views/shop/products/_handle.vue"),
	// 	props: true,
	// 	meta: { menuHeader: shopMenu },
	// },

	{
		path: "/:404(.*)",
		name: "404Page",
		component: () => import("../views/404View.vue"),
	},
	{
		path: '/mini-app',
		name: 'MiniApp',
		component: MiniApp
	},
	{
		path: '/leaflet',
		name: 'Leaflet',
		component: Leaflet
	},
	{
		path: '/leaflet/:id',
		name: 'UserLeaflet',
		component: UserLeaflet
	  },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { left: 0, top: 0 };
	},
});
const verifier = CognitoJwtVerifier.create({
	userPoolId: "us-east-1_jTrm8n2fY",
	tokenUse: "access",
	clientId: null,
});

router.beforeEach(async (to, from, next) => {
	// if(to.query.next) next(to.query.next);

	// if(window.location.host == "longevityintime.shop" && to.path != "/shop"){
	// 	next("longevityintime.shop/sho")
	// }
	let aa = async () => {
		console.log("1 ", "authe");

		let authe = await Auth.currentAuthenticatedUser();
		console.log("2 ", authe);

		let token = await authe?.signInUserSession?.accessToken?.jwtToken;
		console.log("3 ", token);
		if (!token) return false;

		try {
			const payload = await verifier.verify(token);
			// console.log("Token is valid. Payload:", payload);
			return true;
		} catch (e) {
			return false;
		}
	};


	let nn
	try {
		 nn = await aa()
	} catch (error) {
		nn = false
	};
	console.log("nn", nn)
	console.log("opene", window.opener		)

	// let authed = await validateAuth({"X-valid-client":true, body:{...store.state.user}})
	if (window.opener && nn) {
		setTimeout(() => {

			window.opener.postMessage("i");
		}, 500);
	}

	if ("auth" in to.meta && to.meta.auth.required) {
		if (nn) {
			next();
		} else {
			next({
				path: "/login",
				query: { next: to.fullPath },
			});
		}

	} else {
		next();
	}
});
export default router;
